import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    confirmationResult: null,
    isAuth: false,
    actionType: 'login',
    subscription: null, 
    customer: null, 
    payment_method: null,
    list_payment_methods: null,
    user: {
        cell_phone_number: '',
        stripe_billing_status:'trialing',
        dba: '',
        email: '',
        firstname: '',
        id: '',
        lastname: '',
        license_number:'',
        mailing_address: '',
        office_number:'',
        user_level: '',
        agents: [],
    }
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
        const { user, isAuth, actionType, subscription, customer, payment_method, list_payment_methods } = action.payload;
        state.user = { ...state.user, ...user };
        state.isAuth = isAuth;
        state.actionType = actionType;
        state.customer = customer;
        state.subscription = subscription;
        state.payment_method = payment_method;
        state.list_payment_methods = list_payment_methods
    },
    setUpdateUser(state, action) {
        const { user } = action.payload;
        state.user = user;
    },
    setUpdateUserElement(state, action) {
        const { key, value } = action.payload;
        state.user[key] = value;
    },
    setUpdateListPaymenMethod(state, action) {
        const { list_payment_methods } = action.payload;
        state.list_payment_methods = list_payment_methods;
    },
    logOutUser(state) {
        const { isAuth, user } = initialState;
        state.user = user;
        state.isAuth = isAuth;
    },
    setUpdateCustomer(state, action) {
        const { customer } = action.payload;
        state.customer = customer;
    },
    setUpdatePayment(state, action) {
        const { payment_method } = action.payload;
        state.payment_method = payment_method;
    },
    setUpdateSubscription(state, action) {
        const { subscription } = action.payload;
        state.subscription = subscription;
    },
    clearUser(state) {
        state.user = initialState.user;
        state.actionType = initialState.actionType;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setUser,
    setUpdateUser,
    setUpdateUserElement,
    setUpdateCustomer,
    setUpdatePayment,
    logOutUser,
    clearUser,
    setUpdateListPaymenMethod,
    setUpdateSubscription,
} = slice.actions;
