import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import checkoutReducer from './slices/checkout';
import userReducer from './slices/user';
import newHomeReducer from './slices/new-home';  
import homeHuntReducer from './slices/home-hunt';  
import bridgeParamReducer from './slices/bridge-param';
import postsReducer from './slices/posts';
import productsReducer from './slices/subscriptions';
import pageReducer from './slices/page';
import reviewsReducer from './slices/reviews';
import sellFormReducer from './slices/sell-form';
import leadReducer from './slices/lead';
// services
import bridgeDataOutPutBaseQuery from './services/bridgedataoutput/api';
import validatorPhoneAndEmailBaseQuery from './services/salesrush/api';
import blogBaseQuery from './services/blog/api';

// ----------------------------------------------------------------------

const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
};

export const rootReducer = combineReducers({
  [bridgeDataOutPutBaseQuery.reducerPath]: bridgeDataOutPutBaseQuery.reducer,
  [validatorPhoneAndEmailBaseQuery.reducerPath]: validatorPhoneAndEmailBaseQuery.reducer,
  [blogBaseQuery.reducerPath]: blogBaseQuery.reducer,
  checkout: checkoutReducer,
  user: persistReducer(userPersistConfig, userReducer),
  posts: persistReducer(userPersistConfig, postsReducer),
  sellForm: sellFormReducer,
  page: pageReducer,
  reviews: reviewsReducer,
  newhome: newHomeReducer,
  homehunt: homeHuntReducer,
  bridgeParam: bridgeParamReducer,
  products: productsReducer,
  lead: leadReducer
});
