import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    firstname : "",
    lastname: "",
    email:"",
    sellingProperty: "",
    renovationOrUpdateProperty: "",
    listingSale: "",
    standOutMarket: "",
    openToSuggestion: "",
    haveAnySpecificConcernOrQuestion: "",
    address: "",
    apartment: "",
    timeForUpdateAndConsultation: "",
    methodCommunication: "",
    cell_phone_number: "",
    owner: "",
    mailing_address: '',
    mailing_address_st: '',
    mailing_address_city: '',
    mailing_address_state: '',
    mailing_address_zip: ''
}

const sellFormSlice = createSlice({
    name: 'sellform',
    initialState,
    reducers: {
        reset:() => initialState,
        setSellFormValue: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
    }
});

export const { setSellFormValue } = sellFormSlice.actions;

export default sellFormSlice.reducer;
