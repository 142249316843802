import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    reviews: []
};

const slice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setReviews(state, action) {
        const { reviews } = action.payload;
        state.reviews = reviews;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setReviews,
} = slice.actions;