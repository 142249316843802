import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
// import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 32,
        height: 32,
        margin: 'auto',
        marginTop: 2.3,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.41 49.25">
      <defs>  
          <linearGradient id="logo-gradient" x1="50%" y1="0%" x2="50%" y2="100%" > 
              
              <stop offset="0%" stopColor="#7A5FFF">
                  <animate attributeName="stop-color" values={`${theme.palette.primary.main}; ${theme.palette.warning.main}; ${theme.palette.primary.main}`} dur="4s" repeatCount="indefinite"/>
              </stop>

              <stop offset="100%" stopColor="#01FF89">
                  <animate attributeName="stop-color" values={`${theme.palette.primary.main}; ${theme.palette.warning.main}; ${theme.palette.primary.main}`} dur="4s" repeatCount="indefinite"/>
              </stop>

          </linearGradient> 

      </defs>
        <rect fillOpacity="0" width="40.49" height="40.39" />
        <rect fill="url('#logo-gradient')" x="5.82" y="4.77" width="11.39" height="30.54" />
        <path fill="url('#logo-gradient')" d="M21.62,4.77h4.15a7.52,7.52,0,0,1,7.52,7.52v.39a7.52,7.52,0,0,1-7.52,7.52H21.62a0,0,0,0,1,0,0V4.77A0,0,0,0,1,21.62,4.77Z" />
        <polygon fill="url('#logo-gradient')" points="21.61 20.32 21.61 35.31 37.33 35.31 21.61 20.32" />
        <path fill={theme.palette.primary.main} d="M4,49.52V43H6.49a1.48,1.48,0,0,1,.31.07,1.94,1.94,0,0,1,1.29,1,2.21,2.21,0,0,1,.26,1,1.9,1.9,0,0,1-.56,1.37,1.82,1.82,0,0,1-1.34.58l1.81,2.42h-.8L5.29,46.58H6l.26,0a2.2,2.2,0,0,0,.6-.16,1.38,1.38,0,0,0,.61-.53,1.44,1.44,0,0,0,.22-.78,1.4,1.4,0,0,0-.16-.66,1.24,1.24,0,0,0-.43-.5,1.71,1.71,0,0,0-.7-.27,6.3,6.3,0,0,0-.75,0h-1v5.9Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M10,49.52V43H13.5v.58H10.63V46H13.4v.58H10.63v2.39H13.5v.59Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M14.8,49.52,17.57,43h.71L21,49.52h-.69l-.93-2.18h-3l-.93,2.18Zm1.87-2.77h2.48l-1.22-3Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M22.51,49.52V43h.65v5.89h2.65v.59Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M28.12,49.52v-5.9H26.64V43h3.58v.58H28.76v5.9Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M34.77,42.93a3.33,3.33,0,0,1,2.39,1,3.14,3.14,0,0,1,1,2.35,3.4,3.4,0,0,1-3.4,3.38,3.3,3.3,0,0,1-2.37-1,3.15,3.15,0,0,1-1-2.36,3.2,3.2,0,0,1,1-2.38A3.26,3.26,0,0,1,34.77,42.93Zm0,.62A2.75,2.75,0,0,0,32,46.3a2.54,2.54,0,0,0,.82,1.9,2.65,2.65,0,0,0,1.93.8,2.73,2.73,0,0,0,2.74-2.75,2.56,2.56,0,0,0-.81-1.9A2.69,2.69,0,0,0,34.78,43.55Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M39.93,49.52V43h2.49a1.48,1.48,0,0,1,.31.07,1.74,1.74,0,0,1,.55.21,1.84,1.84,0,0,1,.74.75,2.21,2.21,0,0,1,.26,1,1.9,1.9,0,0,1-.56,1.37,1.8,1.8,0,0,1-1.33.58l1.8,2.42h-.8l-2.17-2.94h.72l.27,0a2.41,2.41,0,0,0,.6-.16,1.43,1.43,0,0,0,.61-.53,1.47,1.47,0,0,0,.06-1.44,1.34,1.34,0,0,0-.44-.5,1.66,1.66,0,0,0-.7-.27,6.16,6.16,0,0,0-.75,0h-1v5.9Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M45,44.25A1.22,1.22,0,1,1,46.21,43,1.22,1.22,0,0,1,45,44.25ZM45,42a1,1,0,1,0,1,1A1,1,0,0,0,45,42Z" transform="translate(-3.79 -0.37)" />
        <path fill={theme.palette.primary.main} d="M45.36,43.72,45,43.24h-.3v.48h-.23V42.35h.6a.66.66,0,0,1,.21,0,.6.6,0,0,1,.16.08.45.45,0,0,1,.1.14.58.58,0,0,1,0,.18.41.41,0,0,1,0,.16.75.75,0,0,1-.07.12.27.27,0,0,1-.1.08.31.31,0,0,1-.13.06l.37.52Zm-.08-1.1a.33.33,0,0,0-.21,0h-.35V43h.35a.33.33,0,0,0,.21-.06.22.22,0,0,0,.07-.17A.21.21,0,0,0,45.28,42.62Z" transform="translate(-3.79 -0.37)" />
      </svg>
{/*     <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1655 1267">
    <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
    <path stroke="#8ea4b2" strokeOpacity="0.929" vectorEffect="non-scaling-stroke" d="
      M 874.00 395.71
      L 878.98 393.20
      A 1.03 1.03 0.0 0 1 879.95 393.22
      L 890.81 399.29
      Q 891.63 399.75 891.49 398.83
      L 889.63 386.41
      A 1.11 1.11 0.0 0 1 889.97 385.43
      L 899.44 376.66
      Q 899.87 376.27 899.30 376.18
      L 886.55 374.14
      A 0.87 0.87 0.0 0 1 885.91 373.67
      L 880.25 361.79"
    />
    <path stroke="#95aab8" strokeOpacity="0.878" vectorEffect="non-scaling-stroke" d="
      M 880.25 361.79
      C 878.86 362.20 874.85 371.20 873.96 373.01
      A 1.56 1.56 0.0 0 1 872.78 373.85
      L 860.43 375.44
      Q 859.73 375.53 860.22 376.04
      L 869.16 385.18
      A 0.98 0.97 28.8 0 1 869.42 386.05
      L 866.98 398.27
      Q 866.77 399.30 867.71 398.84
      L 874.00 395.71"
    />
    <path stroke="#95aab8" strokeOpacity="0.878" vectorEffect="non-scaling-stroke" d="
      M 778.50 404.79
      Q 779.74 404.62 780.50 405.51
      A 0.67 0.66 -18.7 0 0 780.99 405.75
      L 781.28 405.75
      A 0.42 0.41 84.9 0 0 781.69 405.25
      L 779.11 393.11
      A 1.22 1.21 60.7 0 1 779.41 392.03
      L 788.02 382.83
      Q 788.56 382.25 787.77 382.17
      L 775.09 380.78
      A 0.98 0.97 -10.5 0 1 774.34 380.27
      L 768.15 368.84
      Q 767.87 368.32 767.63 368.85
      L 763.79 377.25"
    />
    <path stroke="#9eb1be" strokeOpacity="0.827" vectorEffect="non-scaling-stroke" d="
      M 763.79 377.25
      Q 763.26 379.55 761.63 381.20
      A 1.49 1.48 -72.5 0 1 760.81 381.63
      L 749.50 383.71
      Q 748.24 383.94 749.20 384.79
      L 758.42 393.02
      A 1.09 1.06 -66.3 0 1 758.77 393.96
      L 757.23 406.48
      Q 757.12 407.37 757.90 406.91
      L 768.79 400.47
      A 1.02 1.01 -46.4 0 1 769.76 400.44
      L 778.50 404.79"
    />
    <path stroke="#a41f22" vectorEffect="non-scaling-stroke" d="
      M 1123.29 599.00
      Q 1125.14 595.13 1128.45 592.28
      A 0.40 0.40 0.0 0 0 1128.47 591.70
      L 1128.10 591.32
      A 0.28 0.28 0.0 0 1 1128.09 590.94
      L 1128.58 590.37
      Q 1128.94 589.94 1128.42 590.16
      L 1127.92 590.36
      A 0.41 0.41 0.0 0 1 1127.39 590.14
      L 1126.05 586.90
      A 0.70 0.70 0.0 0 1 1126.34 586.03
      Q 1127.85 585.12 1128.74 583.59
      A 1.20 1.17 -77.7 0 1 1129.61 583.02
      L 1130.75 582.89
      Q 1131.42 582.81 1130.81 582.53
      L 1130.26 582.28
      Q 1129.90 582.12 1130.30 582.10
      C 1133.67 581.96 1133.10 579.14 1135.60 578.24
      A 0.23 0.23 0.0 0 0 1135.75 578.04
      L 1135.76 577.74
      Q 1135.77 577.25 1135.39 577.56
      L 1133.76 578.83
      Q 1133.48 579.05 1133.44 578.70
      L 1133.30 577.20
      Q 1133.26 576.86 1133.03 577.10
      L 1132.68 577.44
      Q 1132.37 577.76 1132.28 577.32
      Q 1132.19 576.88 1132.52 576.42
      A 0.83 0.81 -33.9 0 0 1132.65 575.79
      L 1132.34 574.41
      Q 1132.27 574.07 1132.59 574.19
      L 1133.00 574.34
      Q 1133.27 574.44 1133.20 574.16
      Q 1133.02 573.35 1133.48 572.59
      Q 1133.77 572.12 1133.75 572.67
      L 1133.75 572.96
      A 0.25 0.25 0.0 0 0 1134.11 573.19
      Q 1134.61 572.95 1134.49 572.50
      Q 1134.05 570.75 1134.83 572.43
      L 1134.94 572.69
      Q 1135.33 573.53 1135.40 572.60
      L 1135.58 570.29
      A 0.29 0.29 0.0 0 0 1135.33 569.98
      Q 1133.16 569.68 1131.08 569.16
      Q 1128.76 568.59 1131.00 568.98
      Q 1131.59 569.08 1132.04 568.73
      Q 1132.68 568.22 1131.86 568.24
      L 1131.59 568.25
      A 0.18 0.18 0.0 0 1 1131.41 568.02
      L 1131.50 567.73
      A 0.35 0.34 -79.3 0 0 1131.20 567.28
      L 1130.38 567.21
      Q 1129.91 567.17 1130.26 566.86
      L 1130.46 566.68
      Q 1130.90 566.30 1130.06 566.68
      Q 1129.59 566.90 1129.11 566.25
      Q 1128.91 565.97 1128.90 566.32
      L 1128.90 566.60
      Q 1128.88 567.28 1128.63 566.65
      L 1127.36 563.46
      A 0.26 0.25 -13.2 0 0 1127.09 563.30
      Q 1126.88 563.32 1126.71 563.21
      Q 1126.46 563.06 1126.76 563.06
      Q 1127.42 563.08 1127.87 562.70
      Q 1128.40 562.25 1127.72 562.36
      Q 1126.61 562.53 1126.59 561.72
      Q 1126.57 560.55 1126.17 561.78
      L 1125.82 562.83
      Q 1125.71 563.15 1125.62 562.83
      Q 1125.50 562.41 1125.06 562.26
      Q 1124.89 562.21 1125.04 562.11
      Q 1126.35 561.19 1125.73 560.01
      Q 1125.63 559.81 1125.84 559.88
      Q 1126.30 560.04 1126.46 559.74
      A 0.29 0.28 13.8 0 0 1126.21 559.33
      L 1124.64 559.30
      A 0.33 0.32 -85.2 0 1 1124.33 558.93
      L 1124.50 557.72
      A 0.45 0.43 -68.1 0 0 1124.34 557.32
      L 1122.61 555.99
      Q 1122.37 555.80 1122.68 555.81
      L 1122.96 555.82
      A 0.28 0.28 0.0 0 0 1123.24 555.57
      Q 1123.30 555.14 1122.76 555.29
      Q 1122.32 555.41 1122.59 555.04
      Q 1122.84 554.69 1123.14 554.92
      Q 1123.49 555.19 1123.50 554.75
      L 1123.51 554.43
      A 0.23 0.23 0.0 0 0 1123.19 554.21
      Q 1121.89 554.71 1120.67 554.20
      Q 1120.37 554.08 1120.66 553.94
      L 1121.23 553.67
      Q 1121.66 553.47 1121.20 553.34
      L 1120.79 553.23
      Q 1120.44 553.14 1120.78 553.00
      L 1121.18 552.83
      Q 1122.41 552.32 1121.08 552.32
      L 1119.04 552.32
      Q 1117.62 552.32 1119.02 552.07
      Q 1120.11 551.88 1121.68 551.79
      Q 1124.23 551.64 1121.69 551.42
      L 1119.75 551.26
      Q 1119.10 551.20 1119.69 550.92
      L 1120.14 550.71
      A 0.57 0.55 83.3 0 0 1120.46 550.29
      Q 1120.61 549.31 1119.66 549.18
      Q 1119.47 549.15 1119.61 549.01
      L 1119.82 548.81
      A 0.21 0.20 30.9 0 0 1119.73 548.47
      L 1119.20 548.30
      A 0.27 0.27 0.0 0 1 1119.01 547.97
      Q 1119.20 547.28 1119.20 546.49
      Q 1119.20 545.45 1118.81 546.32
      Q 1118.71 546.52 1118.76 546.74
      Q 1118.89 547.38 1118.27 546.73
      L 1115.20 543.45
      A 0.30 0.30 0.0 0 1 1115.30 542.97
      L 1115.82 542.76
      Q 1116.80 542.35 1115.74 542.33
      L 1114.97 542.31
      Q 1112.82 542.26 1115.04 542.00
      Q 1115.59 541.94 1116.06 541.71
      Q 1116.79 541.37 1115.98 541.40
      L 1114.40 541.46
      A 0.53 0.53 0.0 0 1 1113.85 540.97
      L 1113.69 538.82
      A 0.59 0.59 0.0 0 0 1113.04 538.27
      Q 1112.32 538.33 1111.68 538.10
      Q 1109.53 537.30 1111.75 537.75
      L 1112.01 537.80
      Q 1113.75 538.15 1111.92 537.38
      Q 1110.01 536.57 1108.58 534.98
      A 0.18 0.18 0.0 0 0 1108.31 534.97
      L 1108.13 535.15
      Q 1107.41 535.85 1107.56 534.86
      L 1107.63 534.35
      Q 1107.67 534.04 1107.48 534.28
      Q 1105.90 536.21 1103.29 536.05
      A 0.97 0.95 21.9 0 0 1102.50 536.38
      C 1100.88 538.25 1095.16 538.52 1094.19 540.59
      A 0.79 0.79 0.0 0 1 1093.47 541.05
      Q 1092.43 541.05 1092.20 541.79
      A 0.40 0.40 0.0 0 1 1091.77 542.07
      L 1091.32 542.01
      A 0.95 0.95 0.0 0 0 1090.49 542.33
      C 1088.47 544.64 1085.81 543.58 1083.60 545.68
      A 1.15 1.12 -60.9 0 1 1082.60 545.98
      Q 1081.96 545.87 1081.99 546.41
      A 0.54 0.54 0.0 0 1 1081.50 546.98
      C 1078.08 547.29 1074.23 549.74 1071.14 551.33
      C 1067.54 553.18 1062.90 554.74 1058.96 557.04
      Q 1055.39 559.12 1051.38 560.05
      Q 1049.65 560.46 1046.20 562.14
      Q 1043.34 563.55 1041.09 564.34
      Q 1012.78 574.37 1003.50 577.01
      C 960.99 589.07 917.99 597.05 873.07 602.23
      Q 869.90 602.60 867.73 603.00
      C 865.11 603.48 862.23 602.96 859.40 604.06
      A 1.70 1.66 39.5 0 1 858.45 604.14
      C 856.16 603.64 853.95 604.46 852.27 604.65
      Q 820.60 608.17 788.85 610.72
      C 786.76 610.89 784.10 612.32 781.74 611.47
      Q 779.39 610.62 777.18 611.79
      C 774.73 613.08 770.27 611.86 769.31 612.74
      A 1.48 1.46 -67.9 0 1 768.45 613.11
      Q 724.22 616.43 680.59 624.20
      C 647.57 630.09 614.51 640.39 585.46 654.21
      C 532.60 679.36 491.18 718.41 465.45 771.74
      A 0.18 0.17 -66.8 0 1 465.25 771.83
      L 464.95 771.74
      Q 464.50 771.60 464.85 771.28
      L 465.03 771.10
      Q 465.67 770.51 464.80 770.50
      L 464.32 770.50
      A 0.55 0.54 -9.5 0 1 463.82 770.15
      C 462.52 766.91 459.25 763.08 455.29 764.43
      A 0.64 0.63 69.3 0 1 454.50 764.07
      Q 453.73 762.22 451.62 762.11
      A 0.60 0.59 -84.7 0 1 451.06 761.43
      C 451.72 756.75 446.62 760.73 444.72 757.37
      A 0.68 0.68 0.0 0 0 444.00 757.03
      Q 440.33 757.71 439.68 754.01
      A 0.62 0.61 82.7 0 0 439.03 753.50
      L 438.27 753.56
      A 0.45 0.44 82.9 0 1 437.80 753.19
      L 437.51 751.52
      Q 437.38 750.77 436.89 751.35
      L 436.35 752.01
      A 0.99 0.95 65.5 0 0 436.12 752.67
      L 436.17 755.54
      A 0.47 0.46 90.0 0 1 435.70 756.02
      Q 434.87 756.01 434.29 757.00"
    />
    <path stroke="#a41f22" vectorEffect="non-scaling-stroke" d="
      M 665.25 866.96
      Q 665.40 866.32 665.94 866.25
      A 0.81 0.80 -75.4 0 0 666.51 865.91
      L 667.81 864.06
      A 1.39 1.38 -30.9 0 0 668.05 863.11
      L 667.59 859.13
      Q 667.54 858.65 667.65 858.18
      Q 668.53 854.30 667.18 850.45
      C 666.86 849.57 667.01 849.20 667.34 848.42
      A 1.78 1.74 -37.9 0 0 667.47 847.47
      C 666.97 843.70 667.92 839.83 667.87 836.59
      C 667.76 829.47 668.28 824.59 667.63 820.45
      C 666.74 814.76 667.48 808.33 667.24 802.50
      A 0.96 0.96 0.0 0 0 666.72 801.70
      L 665.74 801.22
      A 0.35 0.35 0.0 0 1 665.55 800.84
      Q 665.60 800.54 665.46 800.25"
    />
    <path stroke="#849cab" strokeOpacity="0.988" vectorEffect="non-scaling-stroke" d="
      M 961.69 364.31
      Q 966.15 368.70 970.53 373.26
      A 1.04 1.02 -62.1 0 1 970.80 374.17
      L 968.40 386.53
      Q 968.23 387.38 969.00 386.99
      L 980.33 381.19
      A 0.95 0.94 45.3 0 1 981.23 381.20
      L 992.27 387.43
      Q 993.02 387.85 992.89 387.00
      L 990.95 374.35
      A 0.92 0.89 65.1 0 1 991.23 373.55
      L 1000.63 364.83
      Q 1001.13 364.37 1000.46 364.26
      L 987.79 362.15
      A 1.00 0.99 82.2 0 1 987.07 361.60
      L 981.73 350.04
      Q 981.43 349.41 981.11 350.02
      L 975.04 361.46
      A 1.03 1.03 0.0 0 1 974.28 361.98
      L 961.39 363.55
      A 0.24 0.24 0.0 0 0 961.20 363.86
      Q 961.33 364.31 961.69 364.31"
    />
    </g>
    <path fill="#063754" fillOpacity="0.976" d="
      M 944.00 433.21
      L 940.46 432.94
      Q 939.92 432.90 940.29 432.50
      C 940.63 432.12 941.74 432.25 942.03 432.19
      Q 952.36 430.03 959.19 429.90
      Q 961.12 429.86 962.44 429.53
      C 965.43 428.80 966.70 428.63 969.19 428.52
      Q 969.65 428.50 970.04 428.25
      Q 971.39 427.43 972.77 427.93
      Q 973.00 428.01 973.00 428.00
      L 973.09 427.72
      A 0.08 0.08 0.0 0 1 973.21 427.68
      Q 974.44 428.52 975.08 427.59
      A 1.31 1.26 -77.4 0 1 975.88 427.06
      L 981.70 425.88
      Q 981.70 425.88 981.72 425.93
      L 981.82 426.18
      Q 981.88 426.35 981.94 426.17
      L 982.09 425.74
      A 0.36 0.35 -78.2 0 1 982.45 425.50
      Q 984.55 425.70 986.15 424.23
      A 1.05 1.01 -21.8 0 0 986.49 423.47
      L 986.50 422.81
      A 0.52 0.51 5.1 0 0 986.06 422.29
      L 984.98 422.13
      Q 984.26 422.02 984.95 421.77
      L 985.29 421.64
      Q 985.36 421.62 985.32 421.57
      Q 984.62 420.62 985.04 419.90
      A 0.80 0.78 14.3 0 1 985.70 419.52
      Q 986.22 419.51 985.84 418.68
      Q 985.66 418.30 985.35 418.02
      Q 984.78 417.50 984.88 416.51
      C 984.97 415.71 985.32 415.53 986.04 415.46
      A 0.52 0.51 -3.1 0 0 986.50 414.95
      L 986.50 409.99
      A 0.72 0.70 -73.2 0 0 986.19 409.40
      L 985.60 408.99
      A 0.89 0.89 0.0 0 1 985.23 408.31
      Q 985.15 406.61 983.34 406.07
      Q 982.44 405.81 983.34 405.55
      L 984.07 405.34
      Q 984.95 405.10 983.93 404.81
      Q 983.78 404.77 983.69 404.94
      Q 983.03 406.21 983.06 404.56
      L 983.19 396.93
      Q 983.21 396.19 983.77 396.68
      L 984.27 397.11
      Q 984.32 397.16 984.25 397.17
      L 983.95 397.25
      A 0.22 0.22 0.0 0 0 983.85 397.62
      Q 984.34 398.13 984.86 397.71
      A 0.84 0.81 -20.6 0 0 985.16 397.08
      L 985.17 395.56
      Q 985.18 394.80 984.53 395.20
      Q 983.57 395.80 983.29 394.89
      A 0.41 0.07 74.2 0 1 983.25 394.48
      L 983.52 394.42
      Q 983.66 394.38 983.52 394.34
      L 983.19 394.23
      A 0.16 0.15 11.9 0 1 983.08 394.07
      C 983.20 392.19 986.27 392.70 986.62 394.19
      A 0.56 0.54 -2.9 0 0 987.09 394.60
      L 988.21 394.72
      Q 988.62 394.77 988.97 395.00
      Q 990.15 395.78 991.07 394.84
      A 0.70 0.69 -36.4 0 0 991.19 394.06
      Q 990.86 393.39 991.16 392.70
      Q 991.62 391.66 991.04 391.88
      L 990.57 392.06
      Q 990.16 392.22 990.31 391.80
      Q 990.46 391.39 990.17 391.10
      Q 989.82 390.75 990.31 390.76
      L 992.51 390.79
      Q 993.25 390.80 992.58 390.49
      Q 991.61 390.06 990.56 389.78
      A 0.94 0.92 86.8 0 1 989.92 389.20
      C 989.36 387.68 987.46 388.13 986.70 387.04
      Q 986.47 386.71 986.12 386.52
      L 981.23 383.85
      A 0.40 0.39 -52.4 0 1 981.15 383.21
      L 981.51 382.88
      Q 982.05 382.39 981.33 382.35
      Q 978.04 382.17 975.79 384.46
      A 3.34 3.32 18.6 0 1 973.80 385.44
      Q 971.00 385.76 969.41 388.07
      A 0.59 0.58 -57.6 0 1 968.64 388.25
      C 965.96 386.73 968.34 383.89 967.91 381.54
      A 1.68 1.67 -37.9 0 1 968.04 380.52
      Q 969.60 377.27 969.51 373.69
      A 1.27 1.26 -68.1 0 0 969.14 372.82
      Q 965.01 368.62 960.70 364.60
      C 957.89 361.98 961.90 360.03 963.46 361.85
      A 1.14 1.12 -17.8 0 0 964.26 362.25
      C 964.99 362.29 973.00 361.25 973.22 360.97
      A 0.45 0.45 0.0 0 0 973.03 360.27
      Q 972.20 359.94 971.46 360.25
      Q 968.59 361.42 965.72 361.14
      Q 964.87 361.06 965.50 360.49
      Q 966.13 359.92 966.95 359.97
      A 0.93 0.91 20.3 0 0 967.71 359.63
      L 969.04 358.01
      A 0.72 0.71 26.8 0 1 969.78 357.77
      Q 971.19 358.17 972.53 357.44
      A 0.10 0.10 0.0 0 1 972.67 357.50
      L 972.77 357.85
      Q 972.88 358.19 972.98 357.85
      Q 973.06 357.58 972.95 357.35
      A 0.40 0.38 -11.1 0 0 972.61 357.13
      L 970.51 357.10
      A 0.25 0.25 0.0 0 1 970.29 356.74
      Q 970.82 355.64 971.91 355.62
      A 0.32 0.31 -78.8 0 0 972.20 355.42
      C 972.47 354.77 971.51 354.82 971.18 354.86
      A 1.20 1.19 -28.3 0 1 970.34 354.61
      L 968.35 353.09
      A 0.70 0.70 0.0 0 1 968.10 352.34
      Q 968.37 351.34 968.12 350.50
      A 0.33 0.33 0.0 0 0 967.80 350.26
      L 967.47 350.25
      A 0.45 0.45 0.0 0 1 967.04 349.73
      L 967.12 349.17
      A 1.04 1.03 -13.3 0 1 967.54 348.50
      Q 968.11 348.11 968.17 347.34
      A 0.55 0.55 0.0 0 0 967.63 346.75
      L 966.97 346.74
      A 0.65 0.62 -16.7 0 1 966.48 346.49
      L 965.59 345.30
      A 0.35 0.34 44.3 0 1 965.58 344.90
      L 966.46 343.60
      A 0.21 0.21 0.0 0 0 966.35 343.28
      L 966.06 343.19
      Q 965.28 342.93 966.25 342.75
      Q 966.49 342.70 966.72 342.83
      Q 967.19 343.10 967.11 342.56
      L 967.04 342.05
      A 0.49 0.48 83.8 0 1 967.48 341.50
      Q 967.75 341.48 967.75 341.75
      Q 967.73 342.52 968.00 341.75
      L 968.09 341.49
      A 0.17 0.17 0.0 0 0 967.93 341.26
      L 966.68 341.24
      A 0.59 0.59 0.0 0 1 966.10 340.68
      L 965.96 337.73
      A 0.47 0.45 11.7 0 0 965.69 337.33
      Q 964.49 336.75 964.13 335.50
      A 0.35 0.34 80.0 0 0 963.79 335.26
      L 963.50 335.26
      A 0.32 0.31 78.6 0 1 963.19 335.05
      Q 962.47 333.18 964.15 333.09
      A 0.81 0.80 68.4 0 1 964.81 333.38
      Q 965.12 333.77 965.72 333.92
      A 0.26 0.26 0.0 0 0 966.03 333.60
      Q 965.92 333.20 965.46 333.04
      Q 964.56 332.72 965.49 332.71
      L 965.94 332.70
      Q 967.14 332.67 966.19 332.06
      Q 965.97 331.92 965.98 331.58
      A 0.33 0.32 1.8 0 0 965.66 331.25
      L 965.31 331.25
      A 0.34 0.34 0.0 0 1 964.98 330.98
      C 964.82 330.25 965.73 330.37 965.93 331.02
      Q 966.01 331.27 966.01 331.01
      L 966.00 330.61
      A 0.47 0.47 0.0 0 0 965.54 330.15
      L 962.20 330.07
      A 0.13 0.12 -9.7 0 1 962.08 329.99
      L 961.97 329.67
      A 0.27 0.27 0.0 0 1 962.21 329.31
      L 964.65 329.17
      A 0.51 0.50 85.6 0 0 965.12 328.61
      L 965.05 327.94
      Q 965.02 327.62 964.78 327.85
      C 964.57 328.06 964.33 328.46 964.08 327.95
      A 0.33 0.32 -22.2 0 1 964.27 327.51
      L 966.22 326.80
      Q 968.29 326.06 966.10 326.04
      L 962.95 326.00
      A 0.73 0.73 0.0 0 1 962.28 325.52
      C 961.14 322.28 958.74 325.72 956.62 325.24
      A 0.30 0.30 0.0 0 0 956.26 325.60
      L 956.34 325.96
      A 0.31 0.30 88.7 0 1 955.99 326.33
      C 954.05 326.00 946.69 325.99 945.40 327.57
      A 1.03 1.01 -71.2 0 1 944.66 327.94
      L 936.47 328.20
      A 0.42 0.42 0.0 0 1 936.04 327.74
      Q 936.10 327.19 936.50 326.79
      Q 1000.83 315.58 1062.54 294.25
      C 1081.86 287.58 1102.02 279.65 1119.19 268.44
      Q 1125.89 264.06 1130.38 257.44
      Q 1130.66 257.03 1130.58 257.52
      C 1123.53 297.15 1106.74 337.25 1081.03 368.30
      Q 1036.00 422.66 964.02 431.50
      Q 959.87 432.01 944.00 433.21
      Z
      M 961.69 364.31
      Q 966.15 368.70 970.53 373.26
      A 1.04 1.02 -62.1 0 1 970.80 374.17
      L 968.40 386.53
      Q 968.23 387.38 969.00 386.99
      L 980.33 381.19
      A 0.95 0.94 45.3 0 1 981.23 381.20
      L 992.27 387.43
      Q 993.02 387.85 992.89 387.00
      L 990.95 374.35
      A 0.92 0.89 65.1 0 1 991.23 373.55
      L 1000.63 364.83
      Q 1001.13 364.37 1000.46 364.26
      L 987.79 362.15
      A 1.00 0.99 82.2 0 1 987.07 361.60
      L 981.73 350.04
      Q 981.43 349.41 981.11 350.02
      L 975.04 361.46
      A 1.03 1.03 0.0 0 1 974.28 361.98
      L 961.39 363.55
      A 0.24 0.24 0.0 0 0 961.20 363.86
      Q 961.33 364.31 961.69 364.31
      Z"
    />
    <path fill="#941b1f" d="
      M 1123.29 599.00
      Q 1125.14 595.13 1128.45 592.28
      A 0.40 0.40 0.0 0 0 1128.47 591.70
      L 1128.10 591.32
      A 0.28 0.28 0.0 0 1 1128.09 590.94
      L 1128.58 590.37
      Q 1128.94 589.94 1128.42 590.16
      L 1127.92 590.36
      A 0.41 0.41 0.0 0 1 1127.39 590.14
      L 1126.05 586.90
      A 0.70 0.70 0.0 0 1 1126.34 586.03
      Q 1127.85 585.12 1128.74 583.59
      A 1.20 1.17 -77.7 0 1 1129.61 583.02
      L 1130.75 582.89
      Q 1131.42 582.81 1130.81 582.53
      L 1130.26 582.28
      Q 1129.90 582.12 1130.30 582.10
      C 1133.67 581.96 1133.10 579.14 1135.60 578.24
      A 0.23 0.23 0.0 0 0 1135.75 578.04
      L 1135.76 577.74
      Q 1135.77 577.25 1135.39 577.56
      L 1133.76 578.83
      Q 1133.48 579.05 1133.44 578.70
      L 1133.30 577.20
      Q 1133.26 576.86 1133.03 577.10
      L 1132.68 577.44
      Q 1132.37 577.76 1132.28 577.32
      Q 1132.19 576.88 1132.52 576.42
      A 0.83 0.81 -33.9 0 0 1132.65 575.79
      L 1132.34 574.41
      Q 1132.27 574.07 1132.59 574.19
      L 1133.00 574.34
      Q 1133.27 574.44 1133.20 574.16
      Q 1133.02 573.35 1133.48 572.59
      Q 1133.77 572.12 1133.75 572.67
      L 1133.75 572.96
      A 0.25 0.25 0.0 0 0 1134.11 573.19
      Q 1134.61 572.95 1134.49 572.50
      Q 1134.05 570.75 1134.83 572.43
      L 1134.94 572.69
      Q 1135.33 573.53 1135.40 572.60
      L 1135.58 570.29
      A 0.29 0.29 0.0 0 0 1135.33 569.98
      Q 1133.16 569.68 1131.08 569.16
      Q 1128.76 568.59 1131.00 568.98
      Q 1131.59 569.08 1132.04 568.73
      Q 1132.68 568.22 1131.86 568.24
      L 1131.59 568.25
      A 0.18 0.18 0.0 0 1 1131.41 568.02
      L 1131.50 567.73
      A 0.35 0.34 -79.3 0 0 1131.20 567.28
      L 1130.38 567.21
      Q 1129.91 567.17 1130.26 566.86
      L 1130.46 566.68
      Q 1130.90 566.30 1130.06 566.68
      Q 1129.59 566.90 1129.11 566.25
      Q 1128.91 565.97 1128.90 566.32
      L 1128.90 566.60
      Q 1128.88 567.28 1128.63 566.65
      L 1127.36 563.46
      A 0.26 0.25 -13.2 0 0 1127.09 563.30
      Q 1126.88 563.32 1126.71 563.21
      Q 1126.46 563.06 1126.76 563.06
      Q 1127.42 563.08 1127.87 562.70
      Q 1128.40 562.25 1127.72 562.36
      Q 1126.61 562.53 1126.59 561.72
      Q 1126.57 560.55 1126.17 561.78
      L 1125.82 562.83
      Q 1125.71 563.15 1125.62 562.83
      Q 1125.50 562.41 1125.06 562.26
      Q 1124.89 562.21 1125.04 562.11
      Q 1126.35 561.19 1125.73 560.01
      Q 1125.63 559.81 1125.84 559.88
      Q 1126.30 560.04 1126.46 559.74
      A 0.29 0.28 13.8 0 0 1126.21 559.33
      L 1124.64 559.30
      A 0.33 0.32 -85.2 0 1 1124.33 558.93
      L 1124.50 557.72
      A 0.45 0.43 -68.1 0 0 1124.34 557.32
      L 1122.61 555.99
      Q 1122.37 555.80 1122.68 555.81
      L 1122.96 555.82
      A 0.28 0.28 0.0 0 0 1123.24 555.57
      Q 1123.30 555.14 1122.76 555.29
      Q 1122.32 555.41 1122.59 555.04
      Q 1122.84 554.69 1123.14 554.92
      Q 1123.49 555.19 1123.50 554.75
      L 1123.51 554.43
      A 0.23 0.23 0.0 0 0 1123.19 554.21
      Q 1121.89 554.71 1120.67 554.20
      Q 1120.37 554.08 1120.66 553.94
      L 1121.23 553.67
      Q 1121.66 553.47 1121.20 553.34
      L 1120.79 553.23
      Q 1120.44 553.14 1120.78 553.00
      L 1121.18 552.83
      Q 1122.41 552.32 1121.08 552.32
      L 1119.04 552.32
      Q 1117.62 552.32 1119.02 552.07
      Q 1120.11 551.88 1121.68 551.79
      Q 1124.23 551.64 1121.69 551.42
      L 1119.75 551.26
      Q 1119.10 551.20 1119.69 550.92
      L 1120.14 550.71
      A 0.57 0.55 83.3 0 0 1120.46 550.29
      Q 1120.61 549.31 1119.66 549.18
      Q 1119.47 549.15 1119.61 549.01
      L 1119.82 548.81
      A 0.21 0.20 30.9 0 0 1119.73 548.47
      L 1119.20 548.30
      A 0.27 0.27 0.0 0 1 1119.01 547.97
      Q 1119.20 547.28 1119.20 546.49
      Q 1119.20 545.45 1118.81 546.32
      Q 1118.71 546.52 1118.76 546.74
      Q 1118.89 547.38 1118.27 546.73
      L 1115.20 543.45
      A 0.30 0.30 0.0 0 1 1115.30 542.97
      L 1115.82 542.76
      Q 1116.80 542.35 1115.74 542.33
      L 1114.97 542.31
      Q 1112.82 542.26 1115.04 542.00
      Q 1115.59 541.94 1116.06 541.71
      Q 1116.79 541.37 1115.98 541.40
      L 1114.40 541.46
      A 0.53 0.53 0.0 0 1 1113.85 540.97
      L 1113.69 538.82
      A 0.59 0.59 0.0 0 0 1113.04 538.27
      Q 1112.32 538.33 1111.68 538.10
      Q 1109.53 537.30 1111.75 537.75
      L 1112.01 537.80
      Q 1113.75 538.15 1111.92 537.38
      Q 1110.01 536.57 1108.58 534.98
      A 0.18 0.18 0.0 0 0 1108.31 534.97
      L 1108.13 535.15
      Q 1107.41 535.85 1107.56 534.86
      L 1107.63 534.35
      Q 1107.67 534.04 1107.48 534.28
      Q 1105.90 536.21 1103.29 536.05
      A 0.97 0.95 21.9 0 0 1102.50 536.38
      C 1100.88 538.25 1095.16 538.52 1094.19 540.59
      A 0.79 0.79 0.0 0 1 1093.47 541.05
      Q 1092.43 541.05 1092.20 541.79
      A 0.40 0.40 0.0 0 1 1091.77 542.07
      L 1091.32 542.01
      A 0.95 0.95 0.0 0 0 1090.49 542.33
      C 1088.47 544.64 1085.81 543.58 1083.60 545.68
      A 1.15 1.12 -60.9 0 1 1082.60 545.98
      Q 1081.96 545.87 1081.99 546.41
      A 0.54 0.54 0.0 0 1 1081.50 546.98
      C 1078.08 547.29 1074.23 549.74 1071.14 551.33
      C 1067.54 553.18 1062.90 554.74 1058.96 557.04
      Q 1055.39 559.12 1051.38 560.05
      Q 1049.65 560.46 1046.20 562.14
      Q 1043.34 563.55 1041.09 564.34
      Q 1012.78 574.37 1003.50 577.01
      C 960.99 589.07 917.99 597.05 873.07 602.23
      Q 869.90 602.60 867.73 603.00
      C 865.11 603.48 862.23 602.96 859.40 604.06
      A 1.70 1.66 39.5 0 1 858.45 604.14
      C 856.16 603.64 853.95 604.46 852.27 604.65
      Q 820.60 608.17 788.85 610.72
      C 786.76 610.89 784.10 612.32 781.74 611.47
      Q 779.39 610.62 777.18 611.79
      C 774.73 613.08 770.27 611.86 769.31 612.74
      A 1.48 1.46 -67.9 0 1 768.45 613.11
      Q 724.22 616.43 680.59 624.20
      C 647.57 630.09 614.51 640.39 585.46 654.21
      C 532.60 679.36 491.18 718.41 465.45 771.74
      A 0.18 0.17 -66.8 0 1 465.25 771.83
      L 464.95 771.74
      Q 464.50 771.60 464.85 771.28
      L 465.03 771.10
      Q 465.67 770.51 464.80 770.50
      L 464.32 770.50
      A 0.55 0.54 -9.5 0 1 463.82 770.15
      C 462.52 766.91 459.25 763.08 455.29 764.43
      A 0.64 0.63 69.3 0 1 454.50 764.07
      Q 453.73 762.22 451.62 762.11
      A 0.60 0.59 -84.7 0 1 451.06 761.43
      C 451.72 756.75 446.62 760.73 444.72 757.37
      A 0.68 0.68 0.0 0 0 444.00 757.03
      Q 440.33 757.71 439.68 754.01
      A 0.62 0.61 82.7 0 0 439.03 753.50
      L 438.27 753.56
      A 0.45 0.44 82.9 0 1 437.80 753.19
      L 437.51 751.52
      Q 437.38 750.77 436.89 751.35
      L 436.35 752.01
      A 0.99 0.95 65.5 0 0 436.12 752.67
      L 436.17 755.54
      A 0.47 0.46 90.0 0 1 435.70 756.02
      Q 434.87 756.01 434.29 757.00
      Q 436.55 737.94 442.20 719.68
      Q 444.61 711.90 448.07 700.58
      C 460.51 659.93 486.08 624.10 518.26 596.75
      C 595.96 530.70 705.25 510.76 804.07 510.58
      C 865.74 510.46 928.65 507.10 989.43 496.17
      Q 1018.10 491.01 1040.57 483.34
      C 1069.20 473.55 1095.37 457.13 1118.08 437.23
      Q 1172.25 389.76 1207.36 325.83
      C 1216.44 309.28 1223.88 292.09 1226.78 273.71
      Q 1226.86 273.18 1227.10 273.65
      Q 1227.23 273.91 1227.24 274.24
      Q 1227.80 357.61 1206.99 438.24
      C 1191.83 497.01 1165.34 554.62 1123.29 599.00
      Z"
    />
    <path fill="#093959" fillOpacity="0.855" d="
      M 944.00 433.21
      L 886.25 436.71
      C 880.38 435.90 873.67 437.18 868.04 436.96
      Q 867.51 436.94 867.92 436.59
      C 868.46 436.12 875.90 436.09 877.46 435.86
      A 1.71 1.71 0.0 0 1 878.38 436.00
      Q 879.66 436.58 880.84 435.59
      A 0.64 0.62 67.7 0 0 881.06 435.05
      L 880.90 433.29
      Q 880.77 431.77 881.37 433.18
      L 881.83 434.26
      Q 881.88 434.38 881.92 434.26
      L 882.01 433.97
      A 0.17 0.17 0.0 0 1 882.29 433.89
      Q 882.46 434.04 882.42 434.26
      Q 882.39 434.43 882.53 434.33
      Q 883.85 433.38 885.48 433.61
      A 0.55 0.55 0.0 0 0 886.09 433.21
      C 886.33 432.31 887.55 429.79 886.64 429.32
      A 0.13 0.12 21.6 0 0 886.46 429.39
      L 886.35 429.70
      Q 886.16 430.27 886.06 429.68
      Q 885.69 427.71 886.31 425.82
      Q 886.34 425.73 886.38 425.82
      L 886.50 426.06
      Q 886.95 427.04 886.64 426.01
      Q 885.30 421.67 886.15 417.05
      A 0.18 0.02 -80.1 0 1 886.20 416.88
      L 886.48 416.96
      Q 887.00 417.09 886.65 416.68
      Q 886.04 415.97 884.89 415.98
      Q 884.76 415.98 884.87 415.91
      L 885.16 415.74
      A 0.27 0.27 0.0 0 0 885.23 415.34
      Q 884.84 414.86 884.33 415.23
      Q 883.95 415.51 883.92 415.04
      L 883.85 414.10
      A 0.28 0.28 0.0 0 1 884.14 413.80
      L 884.55 413.82
      A 0.39 0.38 0.7 0 0 884.95 413.44
      L 884.94 406.39
      Q 884.94 405.86 884.48 406.12
      L 884.23 406.27
      Q 883.79 406.52 883.92 406.03
      Q 884.45 403.96 883.75 402.01
      Q 883.61 401.64 883.56 401.81
      L 883.49 402.07
      Q 883.38 402.45 883.28 402.06
      Q 882.99 400.84 883.61 399.74
      A 0.87 0.86 9.7 0 1 884.21 399.32
      Q 884.84 399.21 885.43 399.76
      Q 885.84 400.15 885.84 399.59
      C 885.84 398.60 880.13 395.86 879.05 395.24
      Q 877.81 394.53 879.22 394.77
      Q 880.17 394.93 881.07 395.36
      Q 882.79 396.17 881.29 395.00
      Q 880.14 394.11 878.61 394.04
      A 0.35 0.34 1.7 0 0 878.25 394.37
      L 878.25 394.63
      A 0.54 0.54 0.0 0 1 877.79 395.15
      L 874.00 395.71
      L 878.98 393.20
      A 1.03 1.03 0.0 0 1 879.95 393.22
      L 890.81 399.29
      Q 891.63 399.75 891.49 398.83
      L 889.63 386.41
      A 1.11 1.11 0.0 0 1 889.97 385.43
      L 899.44 376.66
      Q 899.87 376.27 899.30 376.18
      L 886.55 374.14
      A 0.87 0.87 0.0 0 1 885.91 373.67
      L 880.25 361.79
      Q 882.30 362.29 883.51 363.93
      A 0.31 0.31 0.0 0 0 883.98 363.97
      Q 885.73 362.34 884.82 360.08
      A 1.05 1.04 -86.7 0 0 884.35 359.55
      Q 883.70 359.18 883.32 359.76
      Q 882.95 360.31 882.95 359.64
      L 882.94 352.28
      Q 882.94 351.53 882.56 352.18
      Q 882.17 352.87 882.50 353.82
      A 0.32 0.32 0.0 0 1 882.15 354.24
      L 881.84 354.19
      A 0.30 0.30 0.0 0 0 881.50 354.48
      L 881.50 354.81
      Q 881.49 355.18 881.80 354.98
      L 882.03 354.83
      Q 882.27 354.67 882.28 354.95
      L 882.32 355.75
      A 0.65 0.62 -18.8 0 1 882.04 356.29
      Q 881.32 356.78 881.23 357.75
      Q 881.10 359.32 881.04 357.75
      L 880.81 352.31
      A 0.13 0.13 0.0 0 0 880.69 352.19
      L 880.37 352.15
      Q 880.11 352.12 880.29 351.92
      Q 881.60 350.48 880.93 348.79
      A 1.23 1.22 -87.3 0 0 880.35 348.15
      Q 879.66 347.79 879.19 347.17
      A 1.17 1.16 -61.2 0 1 878.95 346.33
      Q 879.09 345.15 878.29 344.93
      A 0.40 0.39 25.6 0 1 878.07 344.32
      L 879.23 342.70
      A 0.44 0.44 0.0 0 1 879.84 342.59
      L 880.35 342.94
      Q 880.50 343.05 880.31 343.06
      L 879.96 343.10
      A 0.48 0.48 0.0 0 0 879.51 343.57
      L 879.47 346.21
      Q 879.46 346.50 879.69 346.33
      C 880.41 345.78 879.30 344.85 880.54 344.15
      A 0.79 0.79 0.0 0 0 880.94 343.48
      L 880.94 342.80
      A 0.64 0.63 10.1 0 0 880.51 342.19
      Q 879.54 341.85 879.98 340.56
      A 0.21 0.21 0.0 0 0 879.81 340.29
      L 879.41 340.23
      Q 879.16 340.19 879.32 340.38
      Q 879.55 340.65 879.51 341.01
      Q 879.12 344.14 878.94 341.00
      Q 878.86 339.51 878.98 337.99
      Q 879.15 335.80 879.33 338.01
      Q 879.46 339.66 880.87 340.46
      Q 881.27 340.69 881.03 340.30
      L 880.89 340.07
      Q 880.13 338.82 881.35 339.95
      Q 881.58 340.16 881.74 340.46
      Q 881.82 340.61 881.84 340.45
      L 881.88 340.12
      A 0.34 0.33 -68.7 0 1 882.40 339.89
      Q 882.55 339.99 882.60 340.19
      Q 882.66 340.43 882.80 340.23
      C 883.39 339.38 882.26 338.84 881.65 339.01
      Q 881.08 339.17 881.43 338.69
      L 881.72 338.30
      A 0.56 0.55 34.5 0 0 881.57 337.51
      L 878.30 335.37
      A 0.14 0.14 0.0 0 1 878.29 335.15
      Q 878.50 334.96 878.25 334.52
      Q 907.54 331.95 936.50 326.79
      Q 936.10 327.19 936.04 327.74
      A 0.42 0.42 0.0 0 0 936.47 328.20
      L 944.66 327.94
      A 1.03 1.01 -71.2 0 0 945.40 327.57
      C 946.69 325.99 954.05 326.00 955.99 326.33
      A 0.31 0.30 88.7 0 0 956.34 325.96
      L 956.26 325.60
      A 0.30 0.30 0.0 0 1 956.62 325.24
      C 958.74 325.72 961.14 322.28 962.28 325.52
      A 0.73 0.73 0.0 0 0 962.95 326.00
      L 966.10 326.04
      Q 968.29 326.06 966.22 326.80
      L 964.27 327.51
      A 0.33 0.32 -22.2 0 0 964.08 327.95
      C 964.33 328.46 964.57 328.06 964.78 327.85
      Q 965.02 327.62 965.05 327.94
      L 965.12 328.61
      A 0.51 0.50 85.6 0 1 964.65 329.17
      L 962.21 329.31
      A 0.27 0.27 0.0 0 0 961.97 329.67
      L 962.08 329.99
      A 0.13 0.12 -9.7 0 0 962.20 330.07
      L 965.54 330.15
      A 0.47 0.47 0.0 0 1 966.00 330.61
      L 966.01 331.01
      Q 966.01 331.27 965.93 331.02
      C 965.73 330.37 964.82 330.25 964.98 330.98
      A 0.34 0.34 0.0 0 0 965.31 331.25
      L 965.66 331.25
      A 0.33 0.32 1.8 0 1 965.98 331.58
      Q 965.97 331.92 966.19 332.06
      Q 967.14 332.67 965.94 332.70
      L 965.49 332.71
      Q 964.56 332.72 965.46 333.04
      Q 965.92 333.20 966.03 333.60
      A 0.26 0.26 0.0 0 1 965.72 333.92
      Q 965.12 333.77 964.81 333.38
      A 0.81 0.80 68.4 0 0 964.15 333.09
      Q 962.47 333.18 963.19 335.05
      A 0.32 0.31 78.6 0 0 963.50 335.26
      L 963.79 335.26
      A 0.35 0.34 80.0 0 1 964.13 335.50
      Q 964.49 336.75 965.69 337.33
      A 0.47 0.45 11.7 0 1 965.96 337.73
      L 966.10 340.68
      A 0.59 0.59 0.0 0 0 966.68 341.24
      L 967.93 341.26
      A 0.17 0.17 0.0 0 1 968.09 341.49
      L 968.00 341.75
      Q 967.73 342.52 967.75 341.75
      Q 967.75 341.48 967.48 341.50
      A 0.49 0.48 83.8 0 0 967.04 342.05
      L 967.11 342.56
      Q 967.19 343.10 966.72 342.83
      Q 966.49 342.70 966.25 342.75
      Q 965.28 342.93 966.06 343.19
      L 966.35 343.28
      A 0.21 0.21 0.0 0 1 966.46 343.60
      L 965.58 344.90
      A 0.35 0.34 44.3 0 0 965.59 345.30
      L 966.48 346.49
      A 0.65 0.62 -16.7 0 0 966.97 346.74
      L 967.63 346.75
      A 0.55 0.55 0.0 0 1 968.17 347.34
      Q 968.11 348.11 967.54 348.50
      A 1.04 1.03 -13.3 0 0 967.12 349.17
      L 967.04 349.73
      A 0.45 0.45 0.0 0 0 967.47 350.25
      L 967.80 350.26
      A 0.33 0.33 0.0 0 1 968.12 350.50
      Q 968.37 351.34 968.10 352.34
      A 0.70 0.70 0.0 0 0 968.35 353.09
      L 970.34 354.61
      A 1.20 1.19 -28.3 0 0 971.18 354.86
      C 971.51 354.82 972.47 354.77 972.20 355.42
      A 0.32 0.31 -78.8 0 1 971.91 355.62
      Q 970.82 355.64 970.29 356.74
      A 0.25 0.25 0.0 0 0 970.51 357.10
      L 972.61 357.13
      A 0.40 0.38 -11.1 0 1 972.95 357.35
      Q 973.06 357.58 972.98 357.85
      Q 972.88 358.19 972.77 357.85
      L 972.67 357.50
      A 0.10 0.10 0.0 0 0 972.53 357.44
      Q 971.19 358.17 969.78 357.77
      A 0.72 0.71 26.8 0 0 969.04 358.01
      L 967.71 359.63
      A 0.93 0.91 20.3 0 1 966.95 359.97
      Q 966.13 359.92 965.50 360.49
      Q 964.87 361.06 965.72 361.14
      Q 968.59 361.42 971.46 360.25
      Q 972.20 359.94 973.03 360.27
      A 0.45 0.45 0.0 0 1 973.22 360.97
      C 973.00 361.25 964.99 362.29 964.26 362.25
      A 1.14 1.12 -17.8 0 1 963.46 361.85
      C 961.90 360.03 957.89 361.98 960.70 364.60
      Q 965.01 368.62 969.14 372.82
      A 1.27 1.26 -68.1 0 1 969.51 373.69
      Q 969.60 377.27 968.04 380.52
      A 1.68 1.67 -37.9 0 0 967.91 381.54
      C 968.34 383.89 965.96 386.73 968.64 388.25
      A 0.59 0.58 -57.6 0 0 969.41 388.07
      Q 971.00 385.76 973.80 385.44
      A 3.34 3.32 18.6 0 0 975.79 384.46
      Q 978.04 382.17 981.33 382.35
      Q 982.05 382.39 981.51 382.88
      L 981.15 383.21
      A 0.40 0.39 -52.4 0 0 981.23 383.85
      L 986.12 386.52
      Q 986.47 386.71 986.70 387.04
      C 987.46 388.13 989.36 387.68 989.92 389.20
      A 0.94 0.92 86.8 0 0 990.56 389.78
      Q 991.61 390.06 992.58 390.49
      Q 993.25 390.80 992.51 390.79
      L 990.31 390.76
      Q 989.82 390.75 990.17 391.10
      Q 990.46 391.39 990.31 391.80
      Q 990.16 392.22 990.57 392.06
      L 991.04 391.88
      Q 991.62 391.66 991.16 392.70
      Q 990.86 393.39 991.19 394.06
      A 0.70 0.69 -36.4 0 1 991.07 394.84
      Q 990.15 395.78 988.97 395.00
      Q 988.62 394.77 988.21 394.72
      L 987.09 394.60
      A 0.56 0.54 -2.9 0 1 986.62 394.19
      C 986.27 392.70 983.20 392.19 983.08 394.07
      A 0.16 0.15 11.9 0 0 983.19 394.23
      L 983.52 394.34
      Q 983.66 394.38 983.52 394.42
      L 983.25 394.48
      A 0.41 0.07 74.2 0 0 983.29 394.89
      Q 983.57 395.80 984.53 395.20
      Q 985.18 394.80 985.17 395.56
      L 985.16 397.08
      A 0.84 0.81 -20.6 0 1 984.86 397.71
      Q 984.34 398.13 983.85 397.62
      A 0.22 0.22 0.0 0 1 983.95 397.25
      L 984.25 397.17
      Q 984.32 397.16 984.27 397.11
      L 983.77 396.68
      Q 983.21 396.19 983.19 396.93
      L 983.06 404.56
      Q 983.03 406.21 983.69 404.94
      Q 983.78 404.77 983.93 404.81
      Q 984.95 405.10 984.07 405.34
      L 983.34 405.55
      Q 982.44 405.81 983.34 406.07
      Q 985.15 406.61 985.23 408.31
      A 0.89 0.89 0.0 0 0 985.60 408.99
      L 986.19 409.40
      A 0.72 0.70 -73.2 0 1 986.50 409.99
      L 986.50 414.95
      A 0.52 0.51 -3.1 0 1 986.04 415.46
      C 985.32 415.53 984.97 415.71 984.88 416.51
      Q 984.78 417.50 985.35 418.02
      Q 985.66 418.30 985.84 418.68
      Q 986.22 419.51 985.70 419.52
      A 0.80 0.78 14.3 0 0 985.04 419.90
      Q 984.62 420.62 985.32 421.57
      Q 985.36 421.62 985.29 421.64
      L 984.95 421.77
      Q 984.26 422.02 984.98 422.13
      L 986.06 422.29
      A 0.52 0.51 5.1 0 1 986.50 422.81
      L 986.49 423.47
      A 1.05 1.01 -21.8 0 1 986.15 424.23
      Q 984.55 425.70 982.45 425.50
      A 0.36 0.35 -78.2 0 0 982.09 425.74
      L 981.94 426.17
      Q 981.88 426.35 981.82 426.18
      L 981.72 425.93
      Q 981.70 425.88 981.70 425.88
      L 975.88 427.06
      A 1.31 1.26 -77.4 0 0 975.08 427.59
      Q 974.44 428.52 973.21 427.68
      A 0.08 0.08 0.0 0 0 973.09 427.72
      L 973.00 428.00
      Q 973.00 428.01 972.77 427.93
      Q 971.39 427.43 970.04 428.25
      Q 969.65 428.50 969.19 428.52
      C 966.70 428.63 965.43 428.80 962.44 429.53
      Q 961.12 429.86 959.19 429.90
      Q 952.36 430.03 942.03 432.19
      C 941.74 432.25 940.63 432.12 940.29 432.50
      Q 939.92 432.90 940.46 432.94
      L 944.00 433.21
      Z"
    />
    <path fill="#0a3a5a" fillOpacity="0.757" d="
      M 878.25 334.52
      Q 878.50 334.96 878.29 335.15
      A 0.14 0.14 0.0 0 0 878.30 335.37
      L 881.57 337.51
      A 0.56 0.55 34.5 0 1 881.72 338.30
      L 881.43 338.69
      Q 881.08 339.17 881.65 339.01
      C 882.26 338.84 883.39 339.38 882.80 340.23
      Q 882.66 340.43 882.60 340.19
      Q 882.55 339.99 882.40 339.89
      A 0.34 0.33 -68.7 0 0 881.88 340.12
      L 881.84 340.45
      Q 881.82 340.61 881.74 340.46
      Q 881.58 340.16 881.35 339.95
      Q 880.13 338.82 880.89 340.07
      L 881.03 340.30
      Q 881.27 340.69 880.87 340.46
      Q 879.46 339.66 879.33 338.01
      Q 879.15 335.80 878.98 337.99
      Q 878.86 339.51 878.94 341.00
      Q 879.12 344.14 879.51 341.01
      Q 879.55 340.65 879.32 340.38
      Q 879.16 340.19 879.41 340.23
      L 879.81 340.29
      A 0.21 0.21 0.0 0 1 879.98 340.56
      Q 879.54 341.85 880.51 342.19
      A 0.64 0.63 10.1 0 1 880.94 342.80
      L 880.94 343.48
      A 0.79 0.79 0.0 0 1 880.54 344.15
      C 879.30 344.85 880.41 345.78 879.69 346.33
      Q 879.46 346.50 879.47 346.21
      L 879.51 343.57
      A 0.48 0.48 0.0 0 1 879.96 343.10
      L 880.31 343.06
      Q 880.50 343.05 880.35 342.94
      L 879.84 342.59
      A 0.44 0.44 0.0 0 0 879.23 342.70
      L 878.07 344.32
      A 0.40 0.39 25.6 0 0 878.29 344.93
      Q 879.09 345.15 878.95 346.33
      A 1.17 1.16 -61.2 0 0 879.19 347.17
      Q 879.66 347.79 880.35 348.15
      A 1.23 1.22 -87.3 0 1 880.93 348.79
      Q 881.60 350.48 880.29 351.92
      Q 880.11 352.12 880.37 352.15
      L 880.69 352.19
      A 0.13 0.13 0.0 0 1 880.81 352.31
      L 881.04 357.75
      Q 881.10 359.32 881.23 357.75
      Q 881.32 356.78 882.04 356.29
      A 0.65 0.62 -18.8 0 0 882.32 355.75
      L 882.28 354.95
      Q 882.27 354.67 882.03 354.83
      L 881.80 354.98
      Q 881.49 355.18 881.50 354.81
      L 881.50 354.48
      A 0.30 0.30 0.0 0 1 881.84 354.19
      L 882.15 354.24
      A 0.32 0.32 0.0 0 0 882.50 353.82
      Q 882.17 352.87 882.56 352.18
      Q 882.94 351.53 882.94 352.28
      L 882.95 359.64
      Q 882.95 360.31 883.32 359.76
      Q 883.70 359.18 884.35 359.55
      A 1.05 1.04 -86.7 0 1 884.82 360.08
      Q 885.73 362.34 883.98 363.97
      A 0.31 0.31 0.0 0 1 883.51 363.93
      Q 882.30 362.29 880.25 361.79
      C 878.86 362.20 874.85 371.20 873.96 373.01
      A 1.56 1.56 0.0 0 1 872.78 373.85
      L 860.43 375.44
      Q 859.73 375.53 860.22 376.04
      L 869.16 385.18
      A 0.98 0.97 28.8 0 1 869.42 386.05
      L 866.98 398.27
      Q 866.77 399.30 867.71 398.84
      L 874.00 395.71
      L 877.79 395.15
      A 0.54 0.54 0.0 0 0 878.25 394.63
      L 878.25 394.37
      A 0.35 0.34 1.7 0 1 878.61 394.04
      Q 880.14 394.11 881.29 395.00
      Q 882.79 396.17 881.07 395.36
      Q 880.17 394.93 879.22 394.77
      Q 877.81 394.53 879.05 395.24
      C 880.13 395.86 885.84 398.60 885.84 399.59
      Q 885.84 400.15 885.43 399.76
      Q 884.84 399.21 884.21 399.32
      A 0.87 0.86 9.7 0 0 883.61 399.74
      Q 882.99 400.84 883.28 402.06
      Q 883.38 402.45 883.49 402.07
      L 883.56 401.81
      Q 883.61 401.64 883.75 402.01
      Q 884.45 403.96 883.92 406.03
      Q 883.79 406.52 884.23 406.27
      L 884.48 406.12
      Q 884.94 405.86 884.94 406.39
      L 884.95 413.44
      A 0.39 0.38 0.7 0 1 884.55 413.82
      L 884.14 413.80
      A 0.28 0.28 0.0 0 0 883.85 414.10
      L 883.92 415.04
      Q 883.95 415.51 884.33 415.23
      Q 884.84 414.86 885.23 415.34
      A 0.27 0.27 0.0 0 1 885.16 415.74
      L 884.87 415.91
      Q 884.76 415.98 884.89 415.98
      Q 886.04 415.97 886.65 416.68
      Q 887.00 417.09 886.48 416.96
      L 886.20 416.88
      A 0.18 0.02 -80.1 0 0 886.15 417.05
      Q 885.30 421.67 886.64 426.01
      Q 886.95 427.04 886.50 426.06
      L 886.38 425.82
      Q 886.34 425.73 886.31 425.82
      Q 885.69 427.71 886.06 429.68
      Q 886.16 430.27 886.35 429.70
      L 886.46 429.39
      A 0.13 0.12 21.6 0 1 886.64 429.32
      C 887.55 429.79 886.33 432.31 886.09 433.21
      A 0.55 0.55 0.0 0 1 885.48 433.61
      Q 883.85 433.38 882.53 434.33
      Q 882.39 434.43 882.42 434.26
      Q 882.46 434.04 882.29 433.89
      A 0.17 0.17 0.0 0 0 882.01 433.97
      L 881.92 434.26
      Q 881.88 434.38 881.83 434.26
      L 881.37 433.18
      Q 880.77 431.77 880.90 433.29
      L 881.06 435.05
      A 0.64 0.62 67.7 0 1 880.84 435.59
      Q 879.66 436.58 878.38 436.00
      A 1.71 1.71 0.0 0 0 877.46 435.86
      C 875.90 436.09 868.46 436.12 867.92 436.59
      Q 867.51 436.94 868.04 436.96
      C 873.67 437.18 880.38 435.90 886.25 436.71
      C 863.84 437.83 841.42 438.49 819.00 439.21
      Q 807.88 438.83 796.74 439.03
      C 794.40 439.07 792.50 437.51 790.01 437.50
      Q 782.21 437.46 774.42 437.26
      A 0.44 0.44 0.0 0 1 773.99 436.81
      L 773.99 436.38
      Q 774.00 435.85 774.24 436.32
      Q 774.45 436.72 774.87 436.78
      Q 775.19 436.81 774.96 436.59
      L 774.74 436.36
      Q 774.47 436.09 774.83 436.22
      Q 777.35 437.14 779.90 435.99
      A 0.23 0.23 0.0 0 1 780.21 436.14
      L 780.33 436.52
      Q 780.56 437.27 780.76 436.51
      Q 781.14 435.06 780.88 433.53
      Q 780.82 433.14 780.64 433.50
      L 780.52 433.74
      Q 779.89 435.01 779.91 433.59
      L 779.97 429.66
      Q 779.97 429.21 779.68 429.55
      L 779.49 429.77
      Q 778.89 430.47 778.88 429.55
      L 778.84 426.02
      A 0.30 0.30 0.0 0 1 779.23 425.74
      L 779.53 425.85
      A 0.21 0.20 17.6 0 0 779.80 425.72
      Q 780.28 424.30 779.83 422.78
      Q 779.47 421.55 779.44 422.84
      L 779.39 424.50
      Q 779.37 425.48 779.06 424.55
      Q 778.59 423.13 779.10 421.50
      Q 779.31 420.82 778.85 421.36
      L 778.49 421.77
      Q 777.89 422.48 777.89 421.55
      L 777.90 418.63
      A 0.48 0.48 0.0 0 1 778.30 418.16
      Q 778.74 418.08 778.93 417.58
      Q 779.00 417.39 778.81 417.47
      L 778.54 417.59
      A 0.41 0.41 0.0 0 1 777.97 417.22
      L 777.94 415.68
      A 0.18 0.17 74.0 0 0 777.68 415.53
      L 777.33 415.74
      Q 776.76 416.07 776.90 415.43
      L 777.01 414.92
      Q 777.20 414.07 777.47 414.90
      L 777.56 415.19
      Q 778.48 418.04 777.94 414.75
      Q 777.73 413.51 778.04 412.25
      Q 778.20 411.62 778.26 412.25
      Q 778.28 412.47 778.49 412.62
      Q 779.06 413.02 779.00 412.33
      L 778.87 410.88
      Q 778.80 410.09 778.46 410.80
      L 778.34 411.04
      Q 778.16 411.42 778.04 411.02
      C 777.76 410.12 778.52 407.05 777.81 406.89
      A 0.29 0.29 0.0 0 1 777.62 406.47
      L 778.50 404.79
      Q 779.74 404.62 780.50 405.51
      A 0.67 0.66 -18.7 0 0 780.99 405.75
      L 781.28 405.75
      A 0.42 0.41 84.9 0 0 781.69 405.25
      L 779.11 393.11
      A 1.22 1.21 60.7 0 1 779.41 392.03
      L 788.02 382.83
      Q 788.56 382.25 787.77 382.17
      L 775.09 380.78
      A 0.98 0.97 -10.5 0 1 774.34 380.27
      L 768.15 368.84
      Q 767.87 368.32 767.63 368.85
      L 763.79 377.25
      C 763.73 375.56 765.34 373.58 764.78 372.45
      Q 764.48 371.82 764.23 372.47
      L 763.23 375.07
      Q 762.91 375.92 762.69 375.04
      Q 762.32 373.57 763.36 372.54
      A 1.45 1.43 -18.9 0 0 763.75 371.76
      L 763.99 370.24
      A 1.44 1.39 74.8 0 1 764.47 369.37
      Q 765.69 368.35 765.21 367.25
      A 0.76 0.75 86.4 0 0 764.73 366.82
      L 763.75 366.52
      Q 762.99 366.29 763.27 367.03
      Q 763.68 368.15 763.26 369.19
      Q 762.88 370.16 762.85 369.12
      L 762.81 367.74
      A 0.36 0.36 0.0 0 0 762.43 367.39
      L 761.00 367.47
      Q 760.38 367.51 760.82 367.06
      L 761.16 366.71
      A 0.08 0.08 0.0 0 0 761.14 366.58
      L 760.81 366.44
      A 0.20 0.20 0.0 0 1 760.75 366.12
      L 761.16 365.72
      A 0.09 0.08 -54.7 0 0 761.14 365.58
      L 760.81 365.44
      A 0.20 0.20 0.0 0 1 760.75 365.12
      L 761.14 364.74
      A 0.08 0.07 37.1 0 0 761.12 364.62
      L 760.83 364.50
      A 0.27 0.27 0.0 0 1 760.69 364.13
      C 761.33 362.90 760.82 361.70 760.90 360.61
      Q 761.00 359.33 760.92 358.03
      Q 760.88 357.48 760.50 357.87
      L 760.29 358.09
      Q 759.97 358.42 759.95 357.97
      L 759.80 354.97
      Q 759.74 353.91 760.36 354.78
      L 760.66 355.19
      Q 760.92 355.55 760.82 354.44
      Q 760.78 354.05 760.50 354.00
      A 0.18 0.11 12.6 0 0 760.31 354.06
      L 760.22 354.39
      Q 760.07 354.89 760.01 354.37
      L 759.85 352.92
      A 0.21 0.20 -83.8 0 1 760.12 352.70
      L 760.56 352.86
      A 0.24 0.23 15.1 0 0 760.87 352.69
      Q 761.02 351.91 760.72 351.12
      Q 760.71 351.10 760.74 351.09
      L 761.00 351.00
      Q 761.45 350.86 761.31 351.31
      Q 761.07 352.12 761.52 352.79
      Q 762.02 353.55 762.01 352.63
      L 762.00 352.14
      Q 761.99 351.56 762.48 351.88
      Q 763.98 352.83 763.75 354.60
      A 0.17 0.17 0.0 0 0 763.91 354.79
      L 764.44 354.82
      A 0.41 0.41 0.0 0 0 764.86 354.51
      Q 765.53 351.91 763.89 349.86
      A 0.17 0.17 0.0 0 1 763.97 349.59
      L 764.53 349.41
      A 0.64 0.63 77.2 0 0 764.96 348.71
      L 764.93 348.46
      A 0.18 0.18 0.0 0 1 765.11 348.25
      L 765.98 348.25
      Q 766.57 348.25 766.00 348.08
      L 765.73 348.00
      Q 764.86 347.74 765.77 347.75
      L 767.72 347.76
      Q 768.89 347.77 767.80 347.35
      L 767.00 347.04
      C 804.56 340.48 839.62 337.09 878.25 334.52
      Z"
    />
    <path fill="#0a3a5b" fillOpacity="0.655" d="
      M 767.00 347.04
      L 767.80 347.35
      Q 768.89 347.77 767.72 347.76
      L 765.77 347.75
      Q 764.86 347.74 765.73 348.00
      L 766.00 348.08
      Q 766.57 348.25 765.98 348.25
      L 765.11 348.25
      A 0.18 0.18 0.0 0 0 764.93 348.46
      L 764.96 348.71
      A 0.64 0.63 77.2 0 1 764.53 349.41
      L 763.97 349.59
      A 0.17 0.17 0.0 0 0 763.89 349.86
      Q 765.53 351.91 764.86 354.51
      A 0.41 0.41 0.0 0 1 764.44 354.82
      L 763.91 354.79
      A 0.17 0.17 0.0 0 1 763.75 354.60
      Q 763.98 352.83 762.48 351.88
      Q 761.99 351.56 762.00 352.14
      L 762.01 352.63
      Q 762.02 353.55 761.52 352.79
      Q 761.07 352.12 761.31 351.31
      Q 761.45 350.86 761.00 351.00
      L 760.74 351.09
      Q 760.71 351.10 760.72 351.12
      Q 761.02 351.91 760.87 352.69
      A 0.24 0.23 15.1 0 1 760.56 352.86
      L 760.12 352.70
      A 0.21 0.20 -83.8 0 0 759.85 352.92
      L 760.01 354.37
      Q 760.07 354.89 760.22 354.39
      L 760.31 354.06
      A 0.18 0.11 12.6 0 1 760.50 354.00
      Q 760.78 354.05 760.82 354.44
      Q 760.92 355.55 760.66 355.19
      L 760.36 354.78
      Q 759.74 353.91 759.80 354.97
      L 759.95 357.97
      Q 759.97 358.42 760.29 358.09
      L 760.50 357.87
      Q 760.88 357.48 760.92 358.03
      Q 761.00 359.33 760.90 360.61
      C 760.82 361.70 761.33 362.90 760.69 364.13
      A 0.27 0.27 0.0 0 0 760.83 364.50
      L 761.12 364.62
      A 0.08 0.07 37.1 0 1 761.14 364.74
      L 760.75 365.12
      A 0.20 0.20 0.0 0 0 760.81 365.44
      L 761.14 365.58
      A 0.09 0.08 -54.7 0 1 761.16 365.72
      L 760.75 366.12
      A 0.20 0.20 0.0 0 0 760.81 366.44
      L 761.14 366.58
      A 0.08 0.08 0.0 0 1 761.16 366.71
      L 760.82 367.06
      Q 760.38 367.51 761.00 367.47
      L 762.43 367.39
      A 0.36 0.36 0.0 0 1 762.81 367.74
      L 762.85 369.12
      Q 762.88 370.16 763.26 369.19
      Q 763.68 368.15 763.27 367.03
      Q 762.99 366.29 763.75 366.52
      L 764.73 366.82
      A 0.76 0.75 86.4 0 1 765.21 367.25
      Q 765.69 368.35 764.47 369.37
      A 1.44 1.39 74.8 0 0 763.99 370.24
      L 763.75 371.76
      A 1.45 1.43 -18.9 0 1 763.36 372.54
      Q 762.32 373.57 762.69 375.04
      Q 762.91 375.92 763.23 375.07
      L 764.23 372.47
      Q 764.48 371.82 764.78 372.45
      C 765.34 373.58 763.73 375.56 763.79 377.25
      Q 763.26 379.55 761.63 381.20
      A 1.49 1.48 -72.5 0 1 760.81 381.63
      L 749.50 383.71
      Q 748.24 383.94 749.20 384.79
      L 758.42 393.02
      A 1.09 1.06 -66.3 0 1 758.77 393.96
      L 757.23 406.48
      Q 757.12 407.37 757.90 406.91
      L 768.79 400.47
      A 1.02 1.01 -46.4 0 1 769.76 400.44
      L 778.50 404.79
      L 777.62 406.47
      A 0.29 0.29 0.0 0 0 777.81 406.89
      C 778.52 407.05 777.76 410.12 778.04 411.02
      Q 778.16 411.42 778.34 411.04
      L 778.46 410.80
      Q 778.80 410.09 778.87 410.88
      L 779.00 412.33
      Q 779.06 413.02 778.49 412.62
      Q 778.28 412.47 778.26 412.25
      Q 778.20 411.62 778.04 412.25
      Q 777.73 413.51 777.94 414.75
      Q 778.48 418.04 777.56 415.19
      L 777.47 414.90
      Q 777.20 414.07 777.01 414.92
      L 776.90 415.43
      Q 776.76 416.07 777.33 415.74
      L 777.68 415.53
      A 0.18 0.17 74.0 0 1 777.94 415.68
      L 777.97 417.22
      A 0.41 0.41 0.0 0 0 778.54 417.59
      L 778.81 417.47
      Q 779.00 417.39 778.93 417.58
      Q 778.74 418.08 778.30 418.16
      A 0.48 0.48 0.0 0 0 777.90 418.63
      L 777.89 421.55
      Q 777.89 422.48 778.49 421.77
      L 778.85 421.36
      Q 779.31 420.82 779.10 421.50
      Q 778.59 423.13 779.06 424.55
      Q 779.37 425.48 779.39 424.50
      L 779.44 422.84
      Q 779.47 421.55 779.83 422.78
      Q 780.28 424.30 779.80 425.72
      A 0.21 0.20 17.6 0 1 779.53 425.85
      L 779.23 425.74
      A 0.30 0.30 0.0 0 0 778.84 426.02
      L 778.88 429.55
      Q 778.89 430.47 779.49 429.77
      L 779.68 429.55
      Q 779.97 429.21 779.97 429.66
      L 779.91 433.59
      Q 779.89 435.01 780.52 433.74
      L 780.64 433.50
      Q 780.82 433.14 780.88 433.53
      Q 781.14 435.06 780.76 436.51
      Q 780.56 437.27 780.33 436.52
      L 780.21 436.14
      A 0.23 0.23 0.0 0 0 779.90 435.99
      Q 777.35 437.14 774.83 436.22
      Q 774.47 436.09 774.74 436.36
      L 774.96 436.59
      Q 775.19 436.81 774.87 436.78
      Q 774.45 436.72 774.24 436.32
      Q 774.00 435.85 773.99 436.38
      L 773.99 436.81
      A 0.44 0.44 0.0 0 0 774.42 437.26
      Q 782.21 437.46 790.01 437.50
      C 792.50 437.51 794.40 439.07 796.74 439.03
      Q 807.88 438.83 819.00 439.21
      Q 791.73 439.22 743.50 439.67
      C 723.69 439.85 703.99 442.40 684.50 444.71
      Q 684.23 443.72 683.37 443.35
      A 0.96 0.96 0.0 0 1 682.82 442.67
      Q 682.67 441.92 682.98 441.20
      A 0.89 0.86 -44.0 0 0 683.00 440.60
      Q 682.47 439.03 683.63 438.10
      Q 684.20 437.64 683.47 437.68
      L 683.05 437.70
      A 0.38 0.37 9.1 0 1 682.68 437.19
      Q 683.33 435.53 682.04 434.75
      A 0.71 0.70 -68.4 0 1 681.72 433.99
      C 682.08 432.43 681.70 430.85 681.84 429.36
      Q 682.15 425.91 680.87 422.65
      A 1.44 1.42 -46.1 0 1 680.86 421.68
      Q 681.27 420.52 680.27 419.98
      A 2.10 0.56 89.0 0 1 679.77 418.24
      Q 679.73 417.44 680.67 417.83
      A 0.94 0.92 -85.9 0 1 681.23 418.49
      L 681.55 419.96
      Q 681.73 420.80 682.00 419.99
      L 682.10 419.70
      Q 682.25 419.26 682.40 419.70
      L 682.48 419.95
      Q 682.88 421.13 682.85 419.88
      L 682.79 417.42
      A 0.20 0.20 0.0 0 0 682.48 417.25
      L 682.14 417.46
      Q 681.67 417.74 681.75 417.20
      L 681.82 416.72
      Q 681.85 416.52 681.67 416.61
      Q 681.51 416.69 681.40 416.87
      Q 681.18 417.25 681.02 416.85
      Q 679.99 414.44 681.49 412.36
      Q 681.95 411.72 681.16 411.74
      L 680.08 411.76
      A 0.36 0.36 0.0 0 1 679.72 411.32
      Q 680.18 409.32 679.69 407.26
      A 0.17 0.15 85.3 0 0 679.58 407.15
      L 679.31 407.11
      Q 678.37 406.94 679.25 406.73
      Q 679.56 406.66 679.56 406.31
      Q 679.56 405.91 679.49 405.99
      L 679.21 406.29
      Q 678.92 406.59 678.99 406.18
      Q 679.06 405.72 678.66 404.95
      A 0.85 0.83 -49.8 0 1 678.61 404.26
      Q 679.41 402.06 678.69 399.86
      Q 678.54 399.40 678.62 398.93
      Q 678.84 397.58 678.16 396.46
      A 0.71 0.71 0.0 0 1 678.15 395.75
      L 679.69 393.01
      Q 679.80 392.81 679.59 392.89
      L 678.38 393.32
      A 0.36 0.36 0.0 0 1 677.90 392.98
      C 677.91 392.17 677.80 387.99 678.24 387.73
      Q 678.86 387.37 678.50 387.21
      Q 677.76 386.87 678.36 386.61
      Q 678.84 386.41 678.37 386.18
      Q 678.22 386.11 678.11 386.18
      A 0.06 0.05 -22.7 0 1 678.03 386.15
      L 676.59 381.99
      A 1.02 1.01 -1.1 0 0 675.93 381.36
      Q 675.04 381.09 674.08 381.34
      Q 673.01 381.62 673.90 380.96
      Q 674.61 380.42 675.31 380.43
      A 0.33 0.32 -76.6 0 0 675.60 380.25
      L 675.72 379.99
      A 0.98 0.88 -38.1 0 0 675.81 379.48
      Q 675.69 378.36 676.18 377.72
      A 0.13 0.12 24.4 0 0 676.10 377.52
      L 675.77 377.46
      Q 675.57 377.43 675.75 377.33
      Q 675.95 377.23 675.96 377.02
      A 0.22 0.21 -4.2 0 0 675.70 376.80
      Q 675.10 376.94 674.95 377.62
      A 0.12 0.10 6.9 0 0 675.06 377.74
      L 675.32 377.75
      A 0.16 0.15 87.6 0 1 675.46 377.88
      C 675.59 378.82 672.01 378.46 671.27 378.46
      A 0.29 0.29 0.0 0 1 671.00 378.06
      Q 671.18 377.61 671.00 377.21
      C 700.48 362.22 733.54 353.02 767.00 347.04
      Z"
    />
    <path fill="#ffffff" d="
      M 961.69 364.31
      Q 961.33 364.31 961.20 363.86
      A 0.24 0.24 0.0 0 1 961.39 363.55
      L 974.28 361.98
      A 1.03 1.03 0.0 0 0 975.04 361.46
      L 981.11 350.02
      Q 981.43 349.41 981.73 350.04
      L 987.07 361.60
      A 1.00 0.99 82.2 0 0 987.79 362.15
      L 1000.46 364.26
      Q 1001.13 364.37 1000.63 364.83
      L 991.23 373.55
      A 0.92 0.89 65.1 0 0 990.95 374.35
      L 992.89 387.00
      Q 993.02 387.85 992.27 387.43
      L 981.23 381.20
      A 0.95 0.94 45.3 0 0 980.33 381.19
      L 969.00 386.99
      Q 968.23 387.38 968.40 386.53
      L 970.80 374.17
      A 1.04 1.02 -62.1 0 0 970.53 373.26
      Q 966.15 368.70 961.69 364.31
      Z"
    />
    <path fill="#ffffff" d="
      M 880.25 361.79
      L 885.91 373.67
      A 0.87 0.87 0.0 0 0 886.55 374.14
      L 899.30 376.18
      Q 899.87 376.27 899.44 376.66
      L 889.97 385.43
      A 1.11 1.11 0.0 0 0 889.63 386.41
      L 891.49 398.83
      Q 891.63 399.75 890.81 399.29
      L 879.95 393.22
      A 1.03 1.03 0.0 0 0 878.98 393.20
      L 874.00 395.71
      L 867.71 398.84
      Q 866.77 399.30 866.98 398.27
      L 869.42 386.05
      A 0.98 0.97 28.8 0 0 869.16 385.18
      L 860.22 376.04
      Q 859.73 375.53 860.43 375.44
      L 872.78 373.85
      A 1.56 1.56 0.0 0 0 873.96 373.01
      C 874.85 371.20 878.86 362.20 880.25 361.79
      Z"
    />
    <path fill="#ffffff" d="
      M 778.50 404.79
      L 769.76 400.44
      A 1.02 1.01 -46.4 0 0 768.79 400.47
      L 757.90 406.91
      Q 757.12 407.37 757.23 406.48
      L 758.77 393.96
      A 1.09 1.06 -66.3 0 0 758.42 393.02
      L 749.20 384.79
      Q 748.24 383.94 749.50 383.71
      L 760.81 381.63
      A 1.49 1.48 -72.5 0 0 761.63 381.20
      Q 763.26 379.55 763.79 377.25
      L 767.63 368.85
      Q 767.87 368.32 768.15 368.84
      L 774.34 380.27
      A 0.98 0.97 -10.5 0 0 775.09 380.78
      L 787.77 382.17
      Q 788.56 382.25 788.02 382.83
      L 779.41 392.03
      A 1.22 1.21 60.7 0 0 779.11 393.11
      L 781.69 405.25
      A 0.42 0.41 84.9 0 1 781.28 405.75
      L 780.99 405.75
      A 0.67 0.66 -18.7 0 1 780.50 405.51
      Q 779.74 404.62 778.50 404.79
      Z"
    />
    <path fill="#083859" fillOpacity="0.573" d="
      M 684.50 444.71
      C 649.03 448.65 615.89 461.53 589.57 485.82
      Q 583.65 491.28 578.74 498.84
      Q 578.45 499.29 578.49 498.76
      C 578.62 496.96 579.63 495.23 579.93 493.61
      C 583.00 477.19 590.71 460.00 598.50 445.65
      C 614.72 415.74 640.38 392.16 671.00 377.21
      Q 671.18 377.61 671.00 378.06
      A 0.29 0.29 0.0 0 0 671.27 378.46
      C 672.01 378.46 675.59 378.82 675.46 377.88
      A 0.16 0.15 87.6 0 0 675.32 377.75
      L 675.06 377.74
      A 0.12 0.10 6.9 0 1 674.95 377.62
      Q 675.10 376.94 675.70 376.80
      A 0.22 0.21 -4.2 0 1 675.96 377.02
      Q 675.95 377.23 675.75 377.33
      Q 675.57 377.43 675.77 377.46
      L 676.10 377.52
      A 0.13 0.12 24.4 0 1 676.18 377.72
      Q 675.69 378.36 675.81 379.48
      A 0.98 0.88 -38.1 0 1 675.72 379.99
      L 675.60 380.25
      A 0.33 0.32 -76.6 0 1 675.31 380.43
      Q 674.61 380.42 673.90 380.96
      Q 673.01 381.62 674.08 381.34
      Q 675.04 381.09 675.93 381.36
      A 1.02 1.01 -1.1 0 1 676.59 381.99
      L 678.03 386.15
      A 0.06 0.05 -22.7 0 0 678.11 386.18
      Q 678.22 386.11 678.37 386.18
      Q 678.84 386.41 678.36 386.61
      Q 677.76 386.87 678.50 387.21
      Q 678.86 387.37 678.24 387.73
      C 677.80 387.99 677.91 392.17 677.90 392.98
      A 0.36 0.36 0.0 0 0 678.38 393.32
      L 679.59 392.89
      Q 679.80 392.81 679.69 393.01
      L 678.15 395.75
      A 0.71 0.71 0.0 0 0 678.16 396.46
      Q 678.84 397.58 678.62 398.93
      Q 678.54 399.40 678.69 399.86
      Q 679.41 402.06 678.61 404.26
      A 0.85 0.83 -49.8 0 0 678.66 404.95
      Q 679.06 405.72 678.99 406.18
      Q 678.92 406.59 679.21 406.29
      L 679.49 405.99
      Q 679.56 405.91 679.56 406.31
      Q 679.56 406.66 679.25 406.73
      Q 678.37 406.94 679.31 407.11
      L 679.58 407.15
      A 0.17 0.15 85.3 0 1 679.69 407.26
      Q 680.18 409.32 679.72 411.32
      A 0.36 0.36 0.0 0 0 680.08 411.76
      L 681.16 411.74
      Q 681.95 411.72 681.49 412.36
      Q 679.99 414.44 681.02 416.85
      Q 681.18 417.25 681.40 416.87
      Q 681.51 416.69 681.67 416.61
      Q 681.85 416.52 681.82 416.72
      L 681.75 417.20
      Q 681.67 417.74 682.14 417.46
      L 682.48 417.25
      A 0.20 0.20 0.0 0 1 682.79 417.42
      L 682.85 419.88
      Q 682.88 421.13 682.48 419.95
      L 682.40 419.70
      Q 682.25 419.26 682.10 419.70
      L 682.00 419.99
      Q 681.73 420.80 681.55 419.96
      L 681.23 418.49
      A 0.94 0.92 -85.9 0 0 680.67 417.83
      Q 679.73 417.44 679.77 418.24
      A 2.10 0.56 89.0 0 0 680.27 419.98
      Q 681.27 420.52 680.86 421.68
      A 1.44 1.42 -46.1 0 0 680.87 422.65
      Q 682.15 425.91 681.84 429.36
      C 681.70 430.85 682.08 432.43 681.72 433.99
      A 0.71 0.70 -68.4 0 0 682.04 434.75
      Q 683.33 435.53 682.68 437.19
      A 0.38 0.37 9.1 0 0 683.05 437.70
      L 683.47 437.68
      Q 684.20 437.64 683.63 438.10
      Q 682.47 439.03 683.00 440.60
      A 0.89 0.86 -44.0 0 1 682.98 441.20
      Q 682.67 441.92 682.82 442.67
      A 0.96 0.96 0.0 0 0 683.37 443.35
      Q 684.23 443.72 684.50 444.71
      Z"
    />
    <path fill="#b42325" d="
      M 1123.29 599.00
      Q 1122.88 599.77 1122.70 599.96
      Q 1065.50 659.07 985.00 673.50
      Q 888.71 690.76 781.42 704.72
      C 757.51 707.83 733.42 710.01 709.45 712.65
      C 690.61 714.72 672.21 718.08 653.78 721.89
      Q 638.69 725.01 634.22 726.12
      C 525.21 753.21 470.87 880.42 443.34 976.53
      C 442.46 979.61 433.89 1010.78 433.43 1010.74
      A 0.23 0.22 89.8 0 1 433.24 1010.54
      Q 432.69 1002.73 432.60 1000.69
      Q 428.20 906.28 427.87 888.44
      C 427.09 846.03 428.48 799.63 434.29 757.00
      Q 434.87 756.01 435.70 756.02
      A 0.47 0.46 90.0 0 0 436.17 755.54
      L 436.12 752.67
      A 0.99 0.95 65.5 0 1 436.35 752.01
      L 436.89 751.35
      Q 437.38 750.77 437.51 751.52
      L 437.80 753.19
      A 0.45 0.44 82.9 0 0 438.27 753.56
      L 439.03 753.50
      A 0.62 0.61 82.7 0 1 439.68 754.01
      Q 440.33 757.71 444.00 757.03
      A 0.68 0.68 0.0 0 1 444.72 757.37
      C 446.62 760.73 451.72 756.75 451.06 761.43
      A 0.60 0.59 -84.7 0 0 451.62 762.11
      Q 453.73 762.22 454.50 764.07
      A 0.64 0.63 69.3 0 0 455.29 764.43
      C 459.25 763.08 462.52 766.91 463.82 770.15
      A 0.55 0.54 -9.5 0 0 464.32 770.50
      L 464.80 770.50
      Q 465.67 770.51 465.03 771.10
      L 464.85 771.28
      Q 464.50 771.60 464.95 771.74
      L 465.25 771.83
      A 0.18 0.17 -66.8 0 0 465.45 771.74
      C 491.18 718.41 532.60 679.36 585.46 654.21
      C 614.51 640.39 647.57 630.09 680.59 624.20
      Q 724.22 616.43 768.45 613.11
      A 1.48 1.46 -67.9 0 0 769.31 612.74
      C 770.27 611.86 774.73 613.08 777.18 611.79
      Q 779.39 610.62 781.74 611.47
      C 784.10 612.32 786.76 610.89 788.85 610.72
      Q 820.60 608.17 852.27 604.65
      C 853.95 604.46 856.16 603.64 858.45 604.14
      A 1.70 1.66 39.5 0 0 859.40 604.06
      C 862.23 602.96 865.11 603.48 867.73 603.00
      Q 869.90 602.60 873.07 602.23
      C 917.99 597.05 960.99 589.07 1003.50 577.01
      Q 1012.78 574.37 1041.09 564.34
      Q 1043.34 563.55 1046.20 562.14
      Q 1049.65 560.46 1051.38 560.05
      Q 1055.39 559.12 1058.96 557.04
      C 1062.90 554.74 1067.54 553.18 1071.14 551.33
      C 1074.23 549.74 1078.08 547.29 1081.50 546.98
      A 0.54 0.54 0.0 0 0 1081.99 546.41
      Q 1081.96 545.87 1082.60 545.98
      A 1.15 1.12 -60.9 0 0 1083.60 545.68
      C 1085.81 543.58 1088.47 544.64 1090.49 542.33
      A 0.95 0.95 0.0 0 1 1091.32 542.01
      L 1091.77 542.07
      A 0.40 0.40 0.0 0 0 1092.20 541.79
      Q 1092.43 541.05 1093.47 541.05
      A 0.79 0.79 0.0 0 0 1094.19 540.59
      C 1095.16 538.52 1100.88 538.25 1102.50 536.38
      A 0.97 0.95 21.9 0 1 1103.29 536.05
      Q 1105.90 536.21 1107.48 534.28
      Q 1107.67 534.04 1107.63 534.35
      L 1107.56 534.86
      Q 1107.41 535.85 1108.13 535.15
      L 1108.31 534.97
      A 0.18 0.18 0.0 0 1 1108.58 534.98
      Q 1110.01 536.57 1111.92 537.38
      Q 1113.75 538.15 1112.01 537.80
      L 1111.75 537.75
      Q 1109.53 537.30 1111.68 538.10
      Q 1112.32 538.33 1113.04 538.27
      A 0.59 0.59 0.0 0 1 1113.69 538.82
      L 1113.85 540.97
      A 0.53 0.53 0.0 0 0 1114.40 541.46
      L 1115.98 541.40
      Q 1116.79 541.37 1116.06 541.71
      Q 1115.59 541.94 1115.04 542.00
      Q 1112.82 542.26 1114.97 542.31
      L 1115.74 542.33
      Q 1116.80 542.35 1115.82 542.76
      L 1115.30 542.97
      A 0.30 0.30 0.0 0 0 1115.20 543.45
      L 1118.27 546.73
      Q 1118.89 547.38 1118.76 546.74
      Q 1118.71 546.52 1118.81 546.32
      Q 1119.20 545.45 1119.20 546.49
      Q 1119.20 547.28 1119.01 547.97
      A 0.27 0.27 0.0 0 0 1119.20 548.30
      L 1119.73 548.47
      A 0.21 0.20 30.9 0 1 1119.82 548.81
      L 1119.61 549.01
      Q 1119.47 549.15 1119.66 549.18
      Q 1120.61 549.31 1120.46 550.29
      A 0.57 0.55 83.3 0 1 1120.14 550.71
      L 1119.69 550.92
      Q 1119.10 551.20 1119.75 551.26
      L 1121.69 551.42
      Q 1124.23 551.64 1121.68 551.79
      Q 1120.11 551.88 1119.02 552.07
      Q 1117.62 552.32 1119.04 552.32
      L 1121.08 552.32
      Q 1122.41 552.32 1121.18 552.83
      L 1120.78 553.00
      Q 1120.44 553.14 1120.79 553.23
      L 1121.20 553.34
      Q 1121.66 553.47 1121.23 553.67
      L 1120.66 553.94
      Q 1120.37 554.08 1120.67 554.20
      Q 1121.89 554.71 1123.19 554.21
      A 0.23 0.23 0.0 0 1 1123.51 554.43
      L 1123.50 554.75
      Q 1123.49 555.19 1123.14 554.92
      Q 1122.84 554.69 1122.59 555.04
      Q 1122.32 555.41 1122.76 555.29
      Q 1123.30 555.14 1123.24 555.57
      A 0.28 0.28 0.0 0 1 1122.96 555.82
      L 1122.68 555.81
      Q 1122.37 555.80 1122.61 555.99
      L 1124.34 557.32
      A 0.45 0.43 -68.1 0 1 1124.50 557.72
      L 1124.33 558.93
      A 0.33 0.32 -85.2 0 0 1124.64 559.30
      L 1126.21 559.33
      A 0.29 0.28 13.8 0 1 1126.46 559.74
      Q 1126.30 560.04 1125.84 559.88
      Q 1125.63 559.81 1125.73 560.01
      Q 1126.35 561.19 1125.04 562.11
      Q 1124.89 562.21 1125.06 562.26
      Q 1125.50 562.41 1125.62 562.83
      Q 1125.71 563.15 1125.82 562.83
      L 1126.17 561.78
      Q 1126.57 560.55 1126.59 561.72
      Q 1126.61 562.53 1127.72 562.36
      Q 1128.40 562.25 1127.87 562.70
      Q 1127.42 563.08 1126.76 563.06
      Q 1126.46 563.06 1126.71 563.21
      Q 1126.88 563.32 1127.09 563.30
      A 0.26 0.25 -13.2 0 1 1127.36 563.46
      L 1128.63 566.65
      Q 1128.88 567.28 1128.90 566.60
      L 1128.90 566.32
      Q 1128.91 565.97 1129.11 566.25
      Q 1129.59 566.90 1130.06 566.68
      Q 1130.90 566.30 1130.46 566.68
      L 1130.26 566.86
      Q 1129.91 567.17 1130.38 567.21
      L 1131.20 567.28
      A 0.35 0.34 -79.3 0 1 1131.50 567.73
      L 1131.41 568.02
      A 0.18 0.18 0.0 0 0 1131.59 568.25
      L 1131.86 568.24
      Q 1132.68 568.22 1132.04 568.73
      Q 1131.59 569.08 1131.00 568.98
      Q 1128.76 568.59 1131.08 569.16
      Q 1133.16 569.68 1135.33 569.98
      A 0.29 0.29 0.0 0 1 1135.58 570.29
      L 1135.40 572.60
      Q 1135.33 573.53 1134.94 572.69
      L 1134.83 572.43
      Q 1134.05 570.75 1134.49 572.50
      Q 1134.61 572.95 1134.11 573.19
      A 0.25 0.25 0.0 0 1 1133.75 572.96
      L 1133.75 572.67
      Q 1133.77 572.12 1133.48 572.59
      Q 1133.02 573.35 1133.20 574.16
      Q 1133.27 574.44 1133.00 574.34
      L 1132.59 574.19
      Q 1132.27 574.07 1132.34 574.41
      L 1132.65 575.79
      A 0.83 0.81 -33.9 0 1 1132.52 576.42
      Q 1132.19 576.88 1132.28 577.32
      Q 1132.37 577.76 1132.68 577.44
      L 1133.03 577.10
      Q 1133.26 576.86 1133.30 577.20
      L 1133.44 578.70
      Q 1133.48 579.05 1133.76 578.83
      L 1135.39 577.56
      Q 1135.77 577.25 1135.76 577.74
      L 1135.75 578.04
      A 0.23 0.23 0.0 0 1 1135.60 578.24
      C 1133.10 579.14 1133.67 581.96 1130.30 582.10
      Q 1129.90 582.12 1130.26 582.28
      L 1130.81 582.53
      Q 1131.42 582.81 1130.75 582.89
      L 1129.61 583.02
      A 1.20 1.17 -77.7 0 0 1128.74 583.59
      Q 1127.85 585.12 1126.34 586.03
      A 0.70 0.70 0.0 0 0 1126.05 586.90
      L 1127.39 590.14
      A 0.41 0.41 0.0 0 0 1127.92 590.36
      L 1128.42 590.16
      Q 1128.94 589.94 1128.58 590.37
      L 1128.09 590.94
      A 0.28 0.28 0.0 0 0 1128.10 591.32
      L 1128.47 591.70
      A 0.40 0.40 0.0 0 1 1128.45 592.28
      Q 1125.14 595.13 1123.29 599.00
      Z"
    />
    <path fill="#941b1f" d="
      M 665.25 866.96
      Q 665.40 866.32 665.94 866.25
      A 0.81 0.80 -75.4 0 0 666.51 865.91
      L 667.81 864.06
      A 1.39 1.38 -30.9 0 0 668.05 863.11
      L 667.59 859.13
      Q 667.54 858.65 667.65 858.18
      Q 668.53 854.30 667.18 850.45
      C 666.86 849.57 667.01 849.20 667.34 848.42
      A 1.78 1.74 -37.9 0 0 667.47 847.47
      C 666.97 843.70 667.92 839.83 667.87 836.59
      C 667.76 829.47 668.28 824.59 667.63 820.45
      C 666.74 814.76 667.48 808.33 667.24 802.50
      A 0.96 0.96 0.0 0 0 666.72 801.70
      L 665.74 801.22
      A 0.35 0.35 0.0 0 1 665.55 800.84
      Q 665.60 800.54 665.46 800.25
      C 727.67 775.46 794.00 776.79 859.43 771.77
      Q 913.83 767.61 967.18 756.17
      Q 994.48 750.32 1020.57 740.51
      C 1032.68 735.96 1047.41 729.32 1054.83 718.19
      Q 1055.10 717.78 1055.00 718.26
      C 1039.44 789.05 993.08 859.49 913.49 864.01
      Q 907.43 864.35 893.50 863.87
      Q 834.43 861.82 775.31 862.30
      C 760.56 862.42 732.87 864.70 710.01 864.73
      C 695.04 864.74 680.12 864.94 665.25 866.96
      Z"
    />
    <path fill="#b42325" d="
      M 665.46 800.25
      Q 665.60 800.54 665.55 800.84
      A 0.35 0.35 0.0 0 0 665.74 801.22
      L 666.72 801.70
      A 0.96 0.96 0.0 0 1 667.24 802.50
      C 667.48 808.33 666.74 814.76 667.63 820.45
      C 668.28 824.59 667.76 829.47 667.87 836.59
      C 667.92 839.83 666.97 843.70 667.47 847.47
      A 1.78 1.74 -37.9 0 1 667.34 848.42
      C 667.01 849.20 666.86 849.57 667.18 850.45
      Q 668.53 854.30 667.65 858.18
      Q 667.54 858.65 667.59 859.13
      L 668.05 863.11
      A 1.39 1.38 -30.9 0 1 667.81 864.06
      L 666.51 865.91
      A 0.81 0.80 -75.4 0 1 665.94 866.25
      Q 665.40 866.32 665.25 866.96
      Q 628.64 871.59 598.09 892.11
      Q 588.58 898.49 586.26 901.51
      Q 582.90 905.86 578.73 909.41
      Q 578.35 909.73 578.45 909.25
      Q 583.79 883.55 594.31 862.07
      C 608.52 833.08 634.72 812.30 665.46 800.25
      Z"
    />
    </svg> */}

    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
