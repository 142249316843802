import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    user: {
        cell_phone_number: '',
        stripe_billing_status:'trialing',
        brokerage_name: '',
        email: '',
        firstname: '',
        id: '',
        lastname: '',
        license_number:'',
        mailing_address: '',
        office_number:'',
        user_level: '',
    }
};

const slice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPage(state, action) {
        const { user } = action.payload;
        state.user = user;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setPage,
} = slice.actions;
