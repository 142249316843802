import { createSlice } from "@reduxjs/toolkit"
import { chain } from "lodash";
import { property_types } from "src/sections/listings/property_types";

const initialState = {
    isLoading: false,
    param: {
        limit: '200',
        sortBy: "ListingContractDate",
        PropertyType: "Residential",
        MlsStatus: "Active",    
        'City.in': undefined,
        'CountyOrParish.in': undefined,
        "PhotosCount.gt": "0",
        "ListPrice.gte": 0,
        "ListPrice.lte": undefined,
        "BedroomsTotal.gte": 0,
        "BedroomsTotal.lte": undefined,
        "BathroomsTotalInteger.gte": 0,
        "BathroomsTotalInteger.lte": undefined,
        "LivingArea.gte": 0,
        "LivingArea.lte": undefined,
        "LotSizeSquareFeet.gte": 0,
        "LotSizeSquareFeet.lte": undefined,
        "box": "-117.62452990710683, 34.24639254060105, -118.78962962025713, 33.84644959939246",
        "PropertySubType.in": chain(property_types)
        .filter(property => property.checked)
        .map('label')
        .join(', ')
        .value(),
    },
    bundle: [],
    isInitialData: true,
    viewport: {
        "longitude": -118.20661286030003,
        "latitude": 34.04768234152283,
        "zoom": 9,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    }
}

const bridgeParamSlice = createSlice({
    name: 'brdigeparam',
    initialState,
    reducers: {
        reset:() => initialState,
        setUpdateParam: (state, action) => {
            const { key, value } = action.payload;
            state.param = {...state.param, [key]:value}
        },
        setBridgeParam: (state, action) => {
            const { param, bundle } = action.payload;
            state.param = param;
            state.bundle = bundle
        },
        setLoading:(state,action)=>{
            const { isLoading } = action.payload;
            state.isLoading = isLoading;
        },
        updateListingData:(state, action) => {
            const { bundle, City, isInitialData } = action.payload;
            state.bundle = bundle;
            state.isInitialData = isInitialData;
            state.City = City;
        },
        setListingData: (state, action) => {
            const {bundle} = action.payload;
            if(state.param.City !== "" && !state.isInitialData) {
                state.bundle.push(...bundle)
            }else{
                state.bundle = bundle;
            }
        },
        setListingBoxParam: (state, action) => {
            const  { box } = action.payload;
            state.param.box = box;
        },
        setViewPort: (state, action) => {
            const { viewport } = action.payload;
            state.viewport = viewport; 
        }
    }
});

export const { setBridgeParam, setListingData, setLoading, updateListingData, setListingBoxParam, setViewPort, setUpdateParam } = bridgeParamSlice.actions;

export default bridgeParamSlice.reducer;
