export const property_types = [
    {
        id: 1,
        label: 'Single Family Residence',
        checked: true,
        type: "housing"
    },
    {
        id: 2,
        label: 'Condominium',
        checked: true,
        type: "housing"
    },
    {
        id: 3,
        label: 'Townhouse',
        checked: true,
        type: "housing"
    },
    {
        id: 4,
        label: 'Manufactured on land',
        checked: true,
        type: "housing"
    },
    {
        id: 5,
        label: 'Loft',
        checked: true,
        type: "housing"
    },
    {
        id: 6,
        label: 'Cabin',
        checked: true,
        type: "housing"
    },
    {
        id: 7,
        label: 'Duplex',
        checked: true,
        type: "unit"
    },
    {
        id: 8,
        label: 'Triplex',
        checked: true,
        type: "unit"
    },
    {
        id: 9,
        label: 'Quadraplex',
        checked: true,
        type: "unit"
    },
/*     {
        id: 10,
        label: 'Multi Family',
        checked: false,
        type: "other"
    },
    {
        id: 11,
        label: 'Agriculture',
        checked: false,
        type: "other"
    },
    {
        id: 12,
        label: 'Boat Slip',
        checked: false,
        type: "other"
    },
    {
        id: 13,
        label: 'Farm',
        checked: false,
        type: "other"
    },
    {
        id: 14,
        label: 'Industrial',
        checked: false,
        type: "other"
    },
    {
        id: 15,
        label: 'Unimproved Land',
        checked: false,
        type: "other"
    },
    {
        id: 16,
        label: 'Warehouse',
        checked: false,
        type: "other"
    }, */
  ];

  export const property_types_forms = [
    {
        id: 1,
        label: 'Single Family Residence',
        checked: true,
        type: "housing"
    },
    {
        id: 2,
        label: 'Condominium',
        checked: true,
        type: "housing"
    },
    {
        id: 3,
        label: 'Townhouse',
        checked: true,
        type: "housing"
    },
    {
        id: 4,
        label: 'Manufactured on land',
        checked: true,
        type: "housing"
    },
    {
        id: 5,
        label: 'Loft',
        checked: true,
        type: "housing"
    },
    {
        id: 6,
        label: 'Cabin',
        checked: true,
        type: "housing"
    },
    {
        id: 7,
        label: 'Duplex',
        checked: true,
        type: "unit"
    },
    {
        id: 8,
        label: 'Triplex',
        checked: true,
        type: "unit"
    },
    {
        id: 9,
        label: 'Quadraplex',
        checked: true,
        type: "unit"
    },
/*     {
        id: 10,
        label: 'Multi Family',
        checked: false,
        type: "other"
    },
    {
        id: 11,
        label: 'Agriculture',
        checked: false,
        type: "other"
    },
    {
        id: 12,
        label: 'Boat Slip',
        checked: false,
        type: "other"
    },
    {
        id: 13,
        label: 'Farm',
        checked: false,
        type: "other"
    },
    {
        id: 14,
        label: 'Industrial',
        checked: false,
        type: "other"
    },
    {
        id: 15,
        label: 'Unimproved Land',
        checked: false,
        type: "other"
    },
    {
        id: 16,
        label: 'Warehouse',
        checked: false,
        type: "other"
    }, */
  ];
  
  