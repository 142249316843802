import PropTypes from 'prop-types';
import { usePathname } from 'src/routes/hook';
import { HeaderSimple } from '../_common';
import Header from '../main/header';

// ----------------------------------------------------------------------

export default function SimpleLayout({ children }) {
  const pathname = usePathname();

  const isLanding = /landing\/.*/.test(pathname);
  return (
    <>
    {
      isLanding ? <Header nav={false}/> : <HeaderSimple /> 
    }

      {children}
    </>
  );
}

SimpleLayout.propTypes = {
  children: PropTypes.node,
};
