import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);


const icon_iconify = (name) => (
  <Iconify icon={name} />
);

const ICONS = {
  
  blog: icon('ic_blog'),
  user: icon('ic_user'),
  external: icon('ic_external'),
  dashboard: icon('ic_dashboard'),
  editor: icon_iconify('solar:clapperboard-edit-bold'),
  billing: icon_iconify('solar:bill-check-bold'),  
  reviews: icon_iconify('solar:star-circle-bold-duotone'), 
  invoice: icon('ic_invoice'),
  label: icon('ic_label'),
  kanban: icon('ic_kanban'),
  setup: icon_iconify('solar:users-group-rounded-bold-duotone'),   
  
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          { title: t('home'), path: paths.dashboard.root, icon: ICONS.dashboard },
          { title: t('contacts'), path: paths.dashboard.user.list, icon: ICONS.user },
          {
            title: t('blog'),
            path: paths.dashboard.post.root,
            icon: ICONS.blog,
            children: [
              { title: t('list'), path: paths.dashboard.post.root },
              { title: t('create'), path: paths.dashboard.post.new },
            ],
          },          
          {
            title: t('reviews'),
            path: paths.dashboard.job.root,
            icon: ICONS.label,
            children: [
              { title: t('list'), path: paths.dashboard.job.root },
              { title: t('create'), path: paths.dashboard.job.new },
            ],
          },
          { title: t('gallery'), path: paths.dashboard.product.new, icon: ICONS.kanban },
          { title: t('setup'), path: paths.dashboard.user.demo.edit, icon: ICONS.setup },
          { title: t('billing'), path: paths.dashboard.blank, icon: ICONS.invoice },
          { title: t('preview'), path: paths.dashboard.general.banking, icon: ICONS.external },
        ],
      },

      
    ],
    [t]
  );

  return data;
}
