import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// components
import { SplashScreen } from 'src/components/loading-screen';
//
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';

import { useDispatch } from 'react-redux';
import { useGetPageQuery } from 'src/redux/services/salesrush/endpoints';
import { setPage } from 'src/redux/slices/page';
import { setReviews } from 'src/redux/slices/reviews';
//
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

export function AuthConsumer({ children }) {

  const [ subdomain ] = useState(window.location.host.split("."));
  /*   if(subdomain.length < 3) {
       window.location = "https://www.salesrush.ai";
    } */
  
    const dispatch = useDispatch();

    const router = useRouter();
  
    const { data, isLoading } = useGetPageQuery(subdomain[0]);
  
    console.log('data', data);
  
    useEffect(() => {    
      if(data && data?.user)
        dispatch(setPage({ user: data?.user }));
      if(data && data?.reviews)
        dispatch(setReviews({ reviews: data?.reviews }));

      if(data?.user.stripe_billing_status === 'paused' || data?.user.stripe_billing_status === 'canceled' || data?.user.stripe_billing_status === 'past_due' ){
        router.push(paths.reactivate);
      }
        
      
    }, [isLoading, data, dispatch, router])
  return (
    <AuthContext.Consumer>
      {(auth) => (auth.loading ? <SplashScreen /> : children)}
    </AuthContext.Consumer>
  );
}

AuthConsumer.propTypes = {
  children: PropTypes.node,
};
