import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    subscriptions: [],

};

const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptions(state, action) {
        const { subscriptions } = action.payload;
        state.subscriptions = subscriptions;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setSubscriptions,
} = slice.actions;
