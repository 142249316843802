import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    cell_phone_number: "",
    form_type: "seller",

}

const leadSlice = createSlice({
    name: 'lead',
    initialState,
    reducers: {
        resetLead:() => initialState,
        setLeadValue: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
    }
});

export const { setLeadValue, resetLead } = leadSlice.actions;

export default leadSlice.reducer;
