import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    posts: []
};

const slice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts(state, action) {
        const { posts } = action.payload;
        state.posts = posts;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setPosts,
} = slice.actions;
