import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hook';
//
import { Fade, useScrollTrigger } from '@mui/material';
//
import Footer from './footer';
import Header from './header';

// ----------------------------------------------------------------------

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

// ----------------------------------------------------------------------

export default function MainLayout({ children }) {
  const pathname = usePathname();
  // const [ subdomain ] = useState(window.location.host.split("."));

/*   if(subdomain.length < 3) {
     window.location = "https://www.salesrush.ai";
  } */

  const isHome = pathname === '/';
  const isListingHomeForSale = pathname.includes("/listings");


  // const dispatch = useDispatch();

  // const { data, isLoading } = useGetPageQuery(subdomain[0]);

  // useEffect(() => {    
  //   if(!isLoading && data && data?.user)
  //     dispatch(setPage({ user: data?.user }));
  //   if(data && data?.reviews)
  //     dispatch(setReviews({ reviews: data?.reviews }));
    
  // }, [isLoading, data, dispatch])

  return (
    <>
    {
      isListingHomeForSale ? (
        <Box>
        <Header fullwidth={isListingHomeForSale} />
          <Box
          component="main"
          sx={{
            flexGrow: 1,
            ...(!isHome && {
              pt: { xs: 8, md: 10 },
            }),
          }}
          >
            {children}
          </Box>
        </Box>
      ):(
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
          <Header />

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              ...(!isHome && {
                pt: { xs: 8, md: 10 },
              }),
            }}
          >
            {children}
          </Box>
          {
            !isListingHomeForSale &&  <Footer />
          }
        </Box>
      )
    }
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
